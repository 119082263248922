import { useState, useEffect, Fragment, React } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SyncIcon from "@mui/icons-material/Sync";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";
import TextField from "@mui/material/TextField";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { jwtDecode } from "jwt-decode";

import {
  getRooms,
  getDevices,
  getAllFirmwareUpdates,
  updateFirmware,
  revertLastFirmware,
} from "../service/service_handler";

export default function UpdateManagerAdmin() {
  const [roomName, setRoomName] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [spectrums, setspectrums] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [roles, setRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [devices, setDevices] = useState([]);
  const [openUpdates, setOpenUpdates] = useState(false);
  const [openUpdatesLoader, setOpenUpdatesLoader] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [updateMsg, setUpdateMsg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [openRevertUpdates, setOpenRevertUpdates] = useState(false);
  const [fileVersion, setFileVersion] = useState("");

  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const jwtdecoded = jwtDecode(localStorage.getItem("token"));
      const rolesarr = jwtdecoded.roles;
      rolesarr.pop();
      setRoles(rolesarr);
      setIsAdmin(jwtdecoded.orgCode == "SUPER_ADMIN" ? true : false);
    }

    getAllFirmwareUpdates()
      .then(function (response) {
        setUpdateData(response.data.updates);
      })
      .catch((error) => {
        console.log("service-error", error.respons);
        if (error.response.status == 401) {
        }
        if (!error.response) {
          console.log("Network Error");
        } else {
          console.log("Service Error");
        }
      });

    // getDevices()
    //   .then(function (response) {
    //     setDevices(response.data.devices);
    //   })
    //   .catch((error) => {
    //     console.log("service-error", error);
    //     if (!error.response) {
    //       setopenSnackErrMsg("Network error please try again");
    //       setOpenSnack(true);
    //       setNoData(true);
    //     } else {
    //       setopenSnackErrMsg(
    //         error.response.data.code + " - Please try again later"
    //       );
    //       setOpenSnack(true);
    //       setNoData(true);
    //     }
    //   });
  }, []);

  const updateFirmwareTable = () => {
    getAllFirmwareUpdates()
      .then(function (response) {
        setUpdateData(response.data.updates);
      })
      .catch((error) => {
        console.log("service-error", error.respons);
        if (error.response.status == 401) {
        }
        if (!error.response) {
          console.log("Network Error");
        } else {
          console.log("Service Error");
        }
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleUpdatesClose = () => {
    setOpenUpdates(!openUpdates);
  };

  const handleRevertUpdatesClose = () => {
    setOpenRevertUpdates(!openRevertUpdates);
  };

  const handleOpenUpdates = () => {
    console.log("updateData: " + JSON.stringify(updateData[0]));
    setOpenUpdates(true);
    setOpenUpdatesLoader(false);
    setUpdateMsg("");
    setFileVersion(updateData[0].newFirmwareVersion);
    setSelectedFile(null);
  };

  const handleOpenRevertUpdates = () => {
    setOpenRevertUpdates(true);
  };

  const confirmUpdate = () => {
    if (
      parseFloat(fileVersion) > parseFloat(updateData[0].newFirmwareVersion)
    ) {
      if (updateMsg.length > 3) {
        if (selectedFile) {
          if (selectedFile.size === 0) {
            setopenSnackErrMsg(
              "The file is empty. Please select a non-empty file."
            );
            setOpenSnack(true);
            return;
          }

          const formData = new FormData();
          formData.append("firmware", selectedFile);
          formData.append("updateMessage", updateMsg);
          formData.append("fileVersion", fileVersion);

          setOpenUpdatesLoader(true);
          // call update trigger
          // const update_firmware_req = {
          //   updateMessage: updateMsg,
          // };
          const reqHeaders = {
            "Content-Type": "multipart/form-data",
          };
          updateFirmware(formData)
            .then(function (response) {
              updateFirmwareTable();
              setopenSnackErrMsg(
                "Successfully updated firmware " + response.data.code
              );
              setOpenSnack(true);
            })
            .catch((error) => {
              console.log("service-error", error);
              if (!error.response) {
                setopenSnackErrMsg("Network error please try again");
                setOpenSnack(true);
                setNoData(true);
              } else {
                setopenSnackErrMsg(
                  error.response.data.code + " - Please try again later"
                );
                setOpenSnack(true);
                setNoData(true);
              }
            });
          setTimeout(function () {
            setOpenUpdates(false);
            setOpenUpdatesLoader(true);
            console.log("closed update box");
            // reload table call
          }, 3000);
        } else {
          setopenSnackErrMsg("No file selected");
          setOpenSnack(true);
        }
      } else {
        setopenSnackErrMsg("Enter a Valid Update message !");
        setOpenSnack(true);
      }
    } else {
      setopenSnackErrMsg("File version is invalid");
      setOpenSnack(true);
    }
  };

  const confirmRevertUpdate = () => {
    const revertReq = {
      type: "REVERT",
    };
    revertLastFirmware(revertReq)
      .then(function (response) {
        updateFirmwareTable();
        setOpenRevertUpdates(false);
        setopenSnackErrMsg(response.data.message);
        setOpenSnack(true);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const cancelUpdate = () => {
    setOpenUpdates(false);
  };
  const cancelRevertUpdate = () => {
    setOpenRevertUpdates(false);
  };

  const handleUpdateMsg = (event) => {
    setUpdateMsg(event.target.value);
  };

  const handleFileVersion = (event) => {
    console.log("newFirmwareVersion: " + updateData[0].newFirmwareVersion);
    // if (parseFloat(event.target.value) >= updateData[0].newFirmwareVersion) {
    //   setFileVersion(event.target.value);
    // }
    setFileVersion(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".bin")) {
      setSelectedFile(file);
    } else {
      alert("Please select a .bin file");
      setSelectedFile(null);
    }
  };

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0 }}
      >
        <Typography
          sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
          variant="h5"
          component="div"
        >
          Firmware Update Manager (Admin)
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ mt: 0 }}
        >
          <Button variant="contained" size="small" onClick={handleOpenUpdates}>
            <CloudUploadIcon />
            &nbsp;New Update
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleOpenRevertUpdates}
          >
            <DeleteForeverIcon />
            &nbsp;Rollback
          </Button>
        </Stack>
      </Stack>

      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />

      <Typography variant="subtitle1" fontWeight={"bold"} sx={{ mt: 2 }}>
        Last 5 Updates:
      </Typography>

      <Dialog open={openUpdates} onClose={handleUpdatesClose}>
        <DialogTitle>Updates</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleUpdatesClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {openUpdatesLoader ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ minWidth: 250 }}
            >
              <CircularProgress size={50} />
              <Typography
                variant="subtitle2"
                sx={{ mt: 3 }}
                color="text.secondary"
                fontWeight={"bold"}
              >
                Upload in progress, Do not close the tab.
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ minWidth: 250 }}
            >
              <TextField
                autoFocus
                margin="dense"
                size="small"
                id="name"
                label="File Version"
                type="number"
                fullWidth
                value={fileVersion}
                onChange={handleFileVersion}
                variant="outlined"
              />
              <TextField
                autoFocus
                margin="dense"
                size="small"
                id="name"
                label="Update Message"
                type="name"
                fullWidth
                value={updateMsg}
                onChange={handleUpdateMsg}
                variant="outlined"
              />

              <input
                accept=".bin"
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span" sx={{ mt: 3 }}>
                  <UploadIcon />
                  &nbsp;Choose Firmware
                </Button>
              </label>
              {selectedFile && (
                <Typography variant="body1" mt={2}>
                  Selected file: {selectedFile.name}
                </Typography>
              )}

              {/* <Button
                sx={{ mt: 3 }}
                variant="contained"
                size="small"
                onClick={handleOpenUpdates}
              >
                <UploadIcon />
                &nbsp;Upload Firmware
              </Button> */}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ mb: 1, mr: 1 }}>
          {!openUpdatesLoader && (
            <div>
              <Button onClick={cancelUpdate}>Cancel</Button>
              <Button onClick={confirmUpdate}>Update</Button>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openRevertUpdates} onClose={handleRevertUpdatesClose}>
        <DialogTitle>Revert Last Update</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleRevertUpdatesClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mt: 0 }}>
            Are you sure you want to revert the last update ?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mb: 1, mr: 1 }}>
          {!openUpdatesLoader && (
            <div>
              <Button onClick={cancelRevertUpdate}>Cancel</Button>
              <Button onClick={confirmRevertUpdate}>Revert</Button>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <TableContainer sx={{ mt: 1 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Update ID</TableCell>
              <TableCell align="right">Update Description</TableCell>
              <TableCell align="right">New Version</TableCell>
              <TableCell align="right">Previous Version</TableCell>
              <TableCell align="right">Release Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updateData.map((row) => (
              <TableRow
                key={row.updateId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{ width: "90px" }} component="th" scope="row">
                  {row.updateId}
                </TableCell>
                <TableCell style={{ width: "140px" }} align="right">
                  {row.updateDescription}
                </TableCell>
                <TableCell style={{ width: "140px" }} align="right">
                  {row.newFirmwareVersion}
                </TableCell>
                <TableCell style={{ width: "140px" }} align="right">
                  {row.previousFirmwareVersion}
                </TableCell>
                <TableCell style={{ width: "140px" }} align="right">
                  {dayjs(row.createdAt).format("lll")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
