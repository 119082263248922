export let alertFlag = false;

export let spectrumData = {};

export const setAlertFlag = (value) => {
  alertFlag = value;
};

export const getAlertFlag = () => {
  return alertFlag;
};

export const setSpectrumData = (value) => {
  spectrumData = value;
};

export const getSpectrumData = () => {
  return spectrumData;
};
