import axiosClient from "../ServiceClient";

export function getDevices(paramsData) {
  return axiosClient.get("/iot/devices");
}

export function getDevicesFilter(paramsData) {
  return axiosClient.get("/iot/devices/filter", {
    params: paramsData,
  });
}

export function getRooms() {
  return axiosClient.get("/iot/rooms");
}

export function getSpectrum(spectrumId) {
  return axiosClient.get("iot/spectrum/" + spectrumId);
}

export function updateSpectrum(spectrumId, data) {
  return axiosClient.put("iot/spectrum/" + spectrumId, JSON.stringify(data));
}

export function getSpectrumByRoomId(roomId) {
  return axiosClient.get("iot/spectrum/room/" + roomId);
}

export function getRoutinesByRoomId(roomId) {
  return axiosClient.get("iot/routines/room/" + roomId);
}

export function createRoutine(data) {
  return axiosClient.post("iot/routines/", JSON.stringify(data));
}

export function createDevice(data) {
  return axiosClient.post("iot/devices/add-device", JSON.stringify(data));
}

export function getUsers() {
  return axiosClient.get("iot/users/");
}

export function addToRoom(deviceId, data) {
  return axiosClient.put("iot/devices/" + deviceId, JSON.stringify(data));
}

export function createUser(data) {
  return axiosClient.post("iot/users/", JSON.stringify(data));
}

export function getLogonToken(data) {
  return axiosClient.post("iot/auth/auth-login/", JSON.stringify(data));
}

export function resetPassword(data) {
  return axiosClient.post("iot/auth/reset/", JSON.stringify(data));
}

export function getAllSpectrum() {
  return axiosClient.get("iot/spectrum/");
}

export function getAuth() {
  return axiosClient.get("iot/auth/auth-status");
}

export function createOrg(data) {
  return axiosClient.post("iot/organization/", JSON.stringify(data));
}

export function getOrgs() {
  return axiosClient.get("iot/organization");
}

export function getDeviceStatus() {
  return axiosClient.get("/iot/devices/status");
}

export function deviceBlink(deviceSerialNo) {
  return axiosClient.get("/iot/devices/blink/" + deviceSerialNo);
}

export function createSpectrum(roomId, data) {
  return axiosClient.post(
    "iot/spectrum/manage/" + roomId,
    JSON.stringify(data)
  );
}

export function updateSpectrumRoom(roomId, data) {
  return axiosClient.put("iot/spectrum/manage/" + roomId, JSON.stringify(data));
}

export function getAllSpectrumByRoomId(roomId) {
  return axiosClient.get("iot/spectrum/room/" + roomId + "/all");
}

export function deleteOrg(orgId) {
  return axiosClient.delete("/iot/organization/" + orgId);
}

export function deleteDevice(deviceId) {
  return axiosClient.delete("/iot/devices/" + deviceId);
}

export function deleteSpectrum(spectrumId) {
  return axiosClient.delete("/iot/spectrum/" + spectrumId);
}

export function updateSpectrumRoomData(roomId, data) {
  return axiosClient.put("iot/spectrum/room/" + roomId, JSON.stringify(data));
}

export function preStatus() {
  return axiosClient.get("/iot/devices/pre-status");
}

export function deleteUser(userId) {
  return axiosClient.delete("/iot/users/" + userId);
}

export function deleteRourines(routineId) {
  return axiosClient.delete("/iot/routines/" + routineId);
}

export function resetUserPassword(data) {
  return axiosClient.put("iot/users/", JSON.stringify(data));
}

export function changeSpectrum(spectrumId, data) {
  return axiosClient.put(
    "iot/spectrum/change-spectrum/" + spectrumId,
    JSON.stringify(data)
  );
}

export function resetSpectrum(roomId, spectrumId, data) {
  return axiosClient.get(
    "iot/spectrum/reset-spectrum/" + roomId + "/" + spectrumId
  );
}

export function updateRoutine(routineId, data) {
  return axiosClient.put("iot/routines/" + routineId, JSON.stringify(data));
}

export function deleteRoom(roomId) {
  return axiosClient.delete("/iot/rooms/" + roomId);
}

export function getAlerts() {
  return axiosClient.get("iot/alerts");
}

export function getLatestFirmwareVersion() {
  return axiosClient.get("iot/firmware/latest");
}

export function getAllFirmwareUpdates() {
  return axiosClient.get("iot/firmware/");
}

export function updateFirmware(formData) {
  return axiosClient.post("iot/firmware/manage", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateOrgFirmwareUpdate(data) {
  return axiosClient.post("iot/firmware/update", JSON.stringify(data));
}

export function revertLastFirmware(data) {
  return axiosClient.post("iot/firmware/revert", JSON.stringify(data));
}
