import { useState, useEffect, Fragment, useRef } from "react";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import useMediaQuery from "@mui/material/useMediaQuery";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import MoreVert from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import SaveIcon from "@mui/icons-material/Save";
import LinearProgress from "@mui/material/LinearProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  setAlertFlag,
  getAlertFlag,
  setSpectrumData,
} from "../global/globalVariables";

// import jwt from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";

import {
  getRooms,
  getSpectrum,
  updateSpectrum,
  getAllSpectrum,
  getSpectrumByRoomId,
  createRoutine,
  createSpectrum,
  updateSpectrumRoom,
  updateSpectrumRoomData,
  getAllSpectrumByRoomId,
  changeSpectrum,
} from "../service/service_handler";

const Input = styled(MuiInput)`
  width: 42px;
`;

// const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
//   "& .MuiToggleButtonGroup-grouped": {
//     margin: theme.spacing(0.5),
//     border: 2,
//     "&.Mui-disabled": {
//       border: 2,
//       color: "primary",
//       background: "black",
//     },
//     "&:not(:first-of-type)": {
//       borderRadius: theme.shape.borderRadius,
//       background: "black",
//     },
//     "&:first-of-type": {
//       borderRadius: theme.shape.borderRadius,
//       background: "black",
//     },
//   },
//   background: "primry",
// }));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.7),
    border: 1,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 1,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: 1,
      borderLeft: "2px solid transparent",
    },
}));

const spectrum_controls = [
  {
    channelName: "Green",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Red",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Yellow",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Blue",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Pink",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Orange",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Channel",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Dummy 1",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Dummy 2",
    channelValue: 10,
    isActive: true,
  },
];

// const roles = [
//   "add-device",
//   "add-user",
//   "add-spectrum",
//   "edit-spectrum",
//   "add-routines",
//   "edit-routines",
// ];
// const roles = jwt.decode(localStorage.getItem("token"));

// localStorage.getItem("token") == ""
//     ? const roles = jwtDecode()
//     : (window.location.href = "/app-login")

// console.log("Roles: " + JSON.stringify(roles));

export default function HomeScreen() {
  const [open, setOpen] = useState(false);
  const [weekNames, setWeekNames] = useState("");
  const [activeRoom, setActiveRoom] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [roomName, setRoomName] = useState("");
  const [roomDetails, setRoomDetails] = useState({});
  const [weeks, setWeeks] = useState(() => []);
  const [value, setValue] = useState([]);
  const [spectrum, setSpectrum] = useState(() => []);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [exisingSchedule, setExisingSchedule] = useState("");
  const [checked, setChecked] = useState(true);
  // const weeksName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [isVisible, setIsVisible] = useState(false);
  const [lockRatio, setLockRatio] = useState(false);
  const [openSpectrum, setOpenSpectrum] = useState(false);
  const [routineNameInput, setRoutineNameInput] = useState("");
  const [routineAction, setRoutineAction] = useState("ON");
  const [routineTime, setRoutineTime] = useState(dayjs(new Date().getTime()));
  const [noRoomData, setNoRoomData] = useState(false);
  const [noSpectrumData, setNoSpectrumData] = useState(false);
  const [spectrumName, setSpectrumName] = useState("");
  const [spectrumNames, setSpectrumNames] = useState([]);
  const [spectrumNameAct, setSpectrumNameAct] = useState({});
  const [openEditName, setOpenEditName] = useState(false);
  const [editedChannelName, setEditedChannelName] = useState("");
  const [noData, setNoData] = useState(false);
  const [roles, setRoles] = useState([]);
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [weekValues, setWeekValues] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [spectrumNameRot, setSpectrumNameRot] = useState("");
  const [spectrumNamesRot, setSpectrumNamesRot] = useState([]);
  const [roomNameRot, setRoomNameRot] = useState("");
  const [spectrumNameText, setSpectrumNameText] = useState("");
  const [lableData, setLableData] = useState({});
  const [spectrumSaveDisabled, setSpectrumSaveDisabled] = useState(true);
  const [masterSpectrumIndex, setMasterSpectrumIndex] = useState(-1);
  const [cachedRoom, setCachedRoom] = useState(0);
  let timeoutId1;
  let timeoutId2;
  // const [name, setName] = useState<string|undefined>(localStorage.getItem("name") || undefined)
  var localizedFormat = require("dayjs/plugin/localizedFormat");
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setRoles(jwtDecode(localStorage.getItem("token")).roles);
      console.log(
        "roles: " + JSON.stringify(jwtDecode(localStorage.getItem("token")))
      );
    }
    var spectrumId;
    getRooms()
      .then(function (response) {
        // const act_room = localStorage.getItem("act_room");
        const cached_room_id = localStorage.getItem("cached_room");
        console.log("cached-room: " + cached_room_id);

        setRoomNames(response.data.rooms);
        let foundIndex = response.data.rooms.findIndex(
          (item) => item.roomId == cached_room_id
        );
        console.log("foundIndex: " + foundIndex);
        if (foundIndex !== -1) {
          console.log("room found - " + foundIndex);
          setActiveRoom(response.data.rooms[foundIndex]);
        } else {
          setActiveRoom(response.data.rooms[0]);
          foundIndex = 0;
          console.log("room not found");
        }

        if (response.data.rooms.length > 0) {
          spectrumId = response.data.rooms[foundIndex].spectrumControlId;
          getSpectrum(response.data.rooms[foundIndex].spectrumControlId)
            .then(function (response) {
              if (response.data.spectrum.orgCode == "SUPER_ADMIN") {
                setSpectrumSaveDisabled(true);
              }
              setSpectrum(response.data.spectrum.channelData);
              setLockRatio(response.data.spectrum.lockRatio);
              // const max_index = response.data.spectrum.channelData.indexOf(
              //   Math.max(...response.data.spectrum.channelData)
              // );
              if (response.data.spectrum.lockRatio != 0) {
                // const max_index = response.data.spectrum.channelData.indexOf(
                //   response.data.spectrum.channelData.reduce(function (
                //     prev,
                //     current
                //   ) {
                //     return prev && prev.channelValue > current.channelValue
                //       ? prev
                //       : current;
                //   })
                // );

                let max_index = -1;
                let max = -Infinity;
                response.data.spectrum.channelData.forEach((obj, index) => {
                  if (obj.isActive && obj.channelValue > max) {
                    max = obj.channelValue;
                    max_index = index;
                  }
                });
                if (max_index == -1) {
                  max_index = 0;
                }
                console.log("max val: " + max_index);
                setMasterSpectrumIndex(max_index);
              }
              // delete response.data.spectrum[0].channelData;
              // console.log(
              //   "ACT spectrum: " + JSON.stringify(response.data.spectrum)
              // );
              // setSpectrumNameAct(response.data.spectrum[0]);
            })
            .catch((error) => {
              console.log("service-error", error);
              if (!error.response) {
                setopenSnackErrMsg("Network error please try again");
                setOpenSnack(true);
                setNoData(true);
              } else {
                setopenSnackErrMsg(
                  error.response.data.code + " - Please try again later"
                );
                setOpenSnack(true);
                setNoData(true);
              }
            });
        }
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });

    getAllSpectrum()
      .then(function (response) {
        console.log(
          "ACT spectrum1: " + JSON.stringify(response.data.spectrums[0])
        );
        // response.data.spectrums.forEach((element) => {
        //   if (element.spectrumControlId == spectrumId) {
        //     setSpectrumNameAct(element);
        //   }
        // });
        setSpectrumNameAct(
          response.data.spectrums.find(
            ({ spectrumControlId }) => spectrumControlId === spectrumId
          )
        );
        // setSpectrumNameAct(response.data.spectrums[0]);
        setSpectrumNames(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      console.log("Component detached");
    };
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "Ok";
    return "Are you sure you want to leave?";
  };

  const updateSpectrumList = () => {
    getAllSpectrum()
      .then(function (response) {
        setSpectrumNames(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleRoomChange = (e, val) => {
    console.log("toggle-val:" + val.spectrumControlId);

    getSpectrum(val.spectrumControlId)
      .then(function (response) {
        if (response.data.spectrum.orgCode == "SUPER_ADMIN") {
          setSpectrumSaveDisabled(true);
        }
        setSpectrum(response.data.spectrum.channelData);
        setLockRatio(response.data.spectrum.lockRatio);

        if (response.data.spectrum.lockRatio != 0) {
          // const max_index = response.data.spectrum.channelData.indexOf(
          //   response.data.spectrum.channelData.reduce(function (prev, current) {
          //     return prev && prev.channelValue > current.channelValue
          //       ? prev
          //       : current;
          //   })
          // );
          let max_index = -1;
          let max = -Infinity;
          response.data.spectrum.channelData.forEach((obj, index) => {
            if (obj.isActive && obj.channelValue > max) {
              max = obj.channelValue;
              max_index = index;
            }
          });
          if (max_index == -1) {
            max_index = 0;
          }
          console.log("max val: " + max_index);
          setMasterSpectrumIndex(max_index);
        }

        delete response.data.spectrum.channelData;
        console.log("ACT spectrum: " + JSON.stringify(response.data.spectrum));
        // setSpectrumNameAct(response.data.spectrum);
        setActiveRoom(val);
        localStorage.setItem("cached_room", val.roomId);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("No Data found");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });

    getAllSpectrum()
      .then(function (response) {
        response.data.spectrums.forEach((element) => {
          if (element.spectrumControlId == val.spectrumControlId) {
            console.log("ACT-spect: " + JSON.stringify(element));
            setSpectrumNameAct(element);
          }
        });
        setSpectrumNames(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleWeeks = (e, val) => {
    console.log("week-name:" + val);
    setWeeks(val);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsVisible(false);
  };

  const handleSpectrumOpen = () => {
    console.log("spectrum " + JSON.stringify(spectrumNameAct));
    setOpenSpectrum(true);
    setIsVisible(false);
  };

  const handleClose = () => {
    setOpen(false);
    // setIsVisible(false);
    // !open ? setIsVisible(false) : setIsVisible(false);
  };

  const handleCloseRoutines = () => {
    setRoutineTime(dayjs(new Date()));
    setRoutineNameInput("");
    setRoutineAction(1);
    setRoomNameRot("");
    setSpectrumNameRot("");
    setWeekValues([0, 0, 0, 0, 0, 0, 0]);
    setOpen(!open);
  };

  const handleSpectrumClose = () => {
    setSpectrumNameText("");
    setOpenSpectrum(false);
    // !openSpectrum ? setIsVisible(false) : setIsVisible(false);
  };

  const handleSaveAlert = () => {
    console.log("handle save");
    window.addEventListener("beforeunload", handleBeforeUnload);
    setAlertFlag(true);
    const specData = {
      roomId: activeRoom.roomId,
      spectrumId: spectrumNameAct.spectrumControlId,
    };
    console.log("cached--spectrum " + JSON.stringify(specData));
    setSpectrumData(specData);
  };

  const handleChange = (event) => {
    console.log("toggle-val:" + event.target.value.spectrumControlId);

    if (roles.includes("edit-spectrum")) {
      const updateSpectrumData = {
        spectrumControlId: event.target.value.spectrumControlId,
      };
      // console.log("updateSpectrumData: ")
      updateSpectrumRoomData(activeRoom.roomId, updateSpectrumData)
        .then(function (response) {
          if (response.data.spectrum.orgCode == "SUPER_ADMIN") {
            setSpectrumSaveDisabled(true);
          }
          setSpectrum(response.data.spectrum.channelData);

          setLockRatio(response.data.spectrum.lockRatio);
          if (response.data.spectrum.lockRatio != 0) {
            // const max_index = response.data.spectrum.channelData.indexOf(
            //   response.data.spectrum.channelData.reduce(function (
            //     prev,
            //     current
            //   ) {
            //     return prev && prev.channelValue > current.channelValue
            //       ? prev
            //       : current;
            //   })
            // );
            let max_index = -1;
            let max = -Infinity;
            response.data.spectrum.channelData.forEach((obj, index) => {
              if (obj.isActive && obj.channelValue > max) {
                max = obj.channelValue;
                max_index = index;
              }
            });
            if (max_index == -1) {
              max_index = 0;
            }
            console.log("max val: " + max_index);
            setMasterSpectrumIndex(max_index);
          }

          // setSpectrumNameAct(response.data.spectrum);4
          console.log("Spect-act:" + JSON.stringify(event.target.value));
          setSpectrumNameAct(event.target.value);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
          }
        });
    }
  };

  const handleSliderChange = (e, index) => {
    if (roles.includes("edit-spectrum")) {
      setSpectrumSaveDisabled(false);
      handleSaveAlert();
      if (lockRatio) {
        const ratios = spectrum.map((value, indexChange) =>
          value.isActive
            ? indexChange === index
              ? 1
              : value.channelValue == 0
              ? 1
              : value.channelValue / spectrum[index].channelValue || 1
            : value.channelValue
        );
        console.log("Ratio: " + ratios);
        setSpectrum(
          spectrum.map((value, index) => {
            if (value.isActive) {
              value.channelValue = parseFloat(e.target.value) * ratios[index];
              console.log("values:" + value);
              return value;
            } else {
              console.log("values:" + value);
              return value;
            }
          })
        );
      } else {
        const newValue = parseFloat(e.target.value);
        setSpectrum((prevValues) => {
          console.log(...prevValues);
          const newValues = [...prevValues];
          newValues[index].channelValue = newValue;
          return newValues;
        });
      }
    }
  };

  const handleInputChange = (event, index) => {
    if (roles.includes("edit-spectrum")) {
      setSpectrumSaveDisabled(false);
      handleSaveAlert();
      if (lockRatio) {
        console.log("spectrum-group");
        const ratios = spectrum.map((value, indexChange) =>
          value.isActive
            ? indexChange === index
              ? 1
              : value.channelValue == 0
              ? 1
              : value.channelValue / spectrum[index].channelValue || 1
            : value.channelValue
        );
        console.log("Ratio: " + ratios);
        setSpectrum(
          spectrum.map((value, index) => {
            console.log(value);
            if (value.isActive) {
              value.channelValue =
                parseFloat(event.target.value) * ratios[index];
              return value;
            } else {
              return value;
            }
          })
        );
      } else {
        const newValue = parseFloat(event.target.value);
        setSpectrum((prevValues) => {
          console.log(...prevValues);
          const newValues = [...prevValues];
          newValues[index].channelValue = newValue;
          return newValues;
        });
      }

      // handleChangeComplete();
      // debounceInputChange();
      // debounce();
    }
  };

  const debounceInputChange = () => {
    console.log("Timeout: " + timeoutId1);
    clearTimeout(timeoutId1);
    timeoutId1 = setTimeout(() => {
      // Perform action or update state once input change is completed
      console.log("Input change completed:");
      handleChangeComplete();
      // You can update state or perform any other action here
    }, 3000); // Adjust the timeout value as needed
  };

  const editLableName = (event, index) => {
    setOpenEditName(!openEditName);
    const edit_lable = {
      index: index,
      channelName: spectrum[index].channelName,
    };
    setEditedChannelName(spectrum[index].channelName);
    setLableData(edit_lable);
  };

  const changeSliderState = (event, index) => {
    console.log("checkbox clicked");
    if (roles.includes("edit-spectrum")) {
      setSpectrumSaveDisabled(false);
      handleSaveAlert();
      setSpectrum((prevValues) => {
        const newValues = [...prevValues];
        newValues[index].isActive = !newValues[index].isActive;
        newValues[index].channelValue = 1;
        return newValues;
      });
    }
  };

  const saveSpectrumData = () => {
    if (roles.includes("edit-spectrum")) {
      const updateSpectrumData = {
        channelData: JSON.stringify(spectrum),
        lockRatio: lockRatio,
        roomId: activeRoom.roomId,
      };
      // console.log("updateSpectrumData: ")
      updateSpectrum(spectrumNameAct.spectrumControlId, updateSpectrumData)
        .then(function (response) {
          console.log("update spectrum" + response.data.code);
          if (response.data.code === "SPECTRUM_UPDATE_FAIL") {
            setopenSnackErrMsg("Failed to update Spectrum Data");
            setOpenSnack(true);
          } else {
            setSpectrumSaveDisabled(true);
            setopenSnackErrMsg("Successfully updated spectrum data");
            setOpenSnack(true);
          }
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
          }
        });
    }
  };

  const handleBlur = () => {
    console.log("On Blur");
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
    handleChangeComplete();
    handleSaveAlert();
  };

  const handleChangeComplete = () => {
    //here you will make your api call
    console.log("Change event completed");
    const updateSpectrumData = {
      channelData: JSON.stringify(spectrum),
      lockRatio: lockRatio,
      roomId: activeRoom.roomId,
    };

    changeSpectrum(spectrumNameAct.spectrumControlId, updateSpectrumData)
      .then(function (response) {
        console.log("update spectrum" + response.data.code);
        if (response.data.code === "SPECTRUM_UPDATE_FAIL") {
          setopenSnackErrMsg("Failed to update Spectrum Data");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg("Successfully updated spectrum data");
          setOpenSnack(true);
        }
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const handleClick = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const showAddOptions = () => {
    setIsVisible(!isVisible);
  };

  const changeRatioState = () => {
    if (roles.includes("edit-spectrum")) {
      console.log(lockRatio);
      const updateSpectrumData = {
        channelData: JSON.stringify(spectrum),
        lockRatio: !lockRatio,
        roomId: spectrumNameAct.roomId,
      };

      if (!lockRatio != 0) {
        // const max_index = spectrum.indexOf(
        //   spectrum.reduce(function (prev, current) {
        //     return prev && prev.channelValue > current.channelValue
        //       ? prev
        //       : current;
        //   })
        // );

        let max_index = -1;
        let max = -Infinity;
        spectrum.forEach((obj, index) => {
          if (obj.isActive && obj.channelValue > max) {
            max = obj.channelValue;
            max_index = index;
          }
        });
        if (max_index == -1) {
          max_index = 0;
        }

        console.log("max val: " + max_index);
        setMasterSpectrumIndex(max_index);
      }

      updateSpectrum(spectrumNameAct.spectrumControlId, updateSpectrumData)
        .then(function (response) {
          console.log("update spectrum" + response.data.code);
          if (response.data.code === "SPECTRUM_UPDATE_FAIL") {
            setopenSnackErrMsg("Failed to update Spectrum Data");
            setOpenSnack(true);
          } else {
            setLockRatio(!lockRatio);
            setopenSnackErrMsg("Successfully updated spectrum data");
            setOpenSnack(true);
          }
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
          }
        });
    }
  };

  const handleActionChange = (event, action) => {
    setRoutineAction(action);
  };

  const handleRoutineNameChange = (event) => {
    setRoutineNameInput(event.target.value);
  };

  const handleSave = () => {
    console.log(routineNameInput);
    console.log(routineTime);
    console.log(routineAction);
    console.log(roomName);
    console.log(spectrumName);

    setOpen(!open);
  };

  const handleSpectrumChange = (event) => {
    setSpectrumNameRot(event.target.value);
  };

  const handleEditClose = () => {
    setOpenEditName(!openEditName);
  };

  const handleEditSave = (event, index) => {
    setSpectrum((prevValues) => {
      const newValues = [...prevValues];
      newValues[lableData.index].channelName = editedChannelName;
      return newValues;
    });

    const updateSpectrumData = {
      channelData: JSON.stringify(spectrum),
      lockRatio: lockRatio,
      roomId: spectrumNameAct.roomId,
    };

    updateSpectrum(spectrumNameAct.spectrumControlId, updateSpectrumData)
      .then(function (response) {
        console.log("update spectrum" + response.data.code);
        if (response.data.code === "SPECTRUM_UPDATE_FAIL") {
          setopenSnackErrMsg("Failed to update Spectrum Data");
          setOpenSnack(true);
        } else {
          setOpenEditName(!openEditName);
          setopenSnackErrMsg("Successfully updated spectrum name");
          setOpenSnack(true);
        }
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const handleLableChange = (event) => {
    setEditedChannelName(event.target.value);
  };

  const handleWeekCheckbox = (event, index) => {
    setWeekValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = 1;
      return newValues;
    });
    console.log(weekValues);
  };

  const handleSaveRoutines = () => {
    var utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    // console.log(dayjs.utc().format());
    console.log("name" + routineNameInput);
    // console.log(dayjs.utc(routineTime).format().split("T")[1].split("Z")[0]);
    // console.log("rot: " + routineTime);
    // console.log(routineAction);
    console.log(roomName);
    console.log(spectrumName);
    // console.log(weekValues);
    if (routineNameInput != "" && roomNameRot && spectrumNameRot) {
      const routine_data = {
        routineName: routineNameInput,
        action: parseInt(routineAction),
        daysInWeek: weekValues.toString(),
        spectrumId: spectrumName.spectrumId,
        spectrumName: spectrumName.spectrumName,
        actionTime: dayjs.utc(routineTime).format().split("T")[1].split("Z")[0],
        roomId: roomName.roomId,
      };
      createRoutine(routine_data)
        .then(function (response) {
          setOpen(!open);
          setopenSnackErrMsg("Successfully Created routine");
          setOpenSnack(true);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid data to continue");
      setOpenSnack(true);
    }
  };

  const handleRoomChangeRoutine = (event) => {
    getAllSpectrum()
      .then(function (response) {
        console.log(response.data.spectrums);
        setRoomNameRot(event.target.value);
        setSpectrumNamesRot(response.data.spectrums);
        setSpectrumNameRot(response.data.spectrums[0]);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          noSpectrumData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoSpectrumData(true);
        }
      });
  };

  const handleNewSpectrum = () => {
    const spectrum_data = {
      roomId: activeRoom.roomId,
      lockRatio: lockRatio,
      spectrumName: spectrumNameText,
      spectrumControl: JSON.stringify(spectrum),
    };
    createSpectrum(activeRoom.roomId, spectrum_data)
      .then(function (response) {
        setOpenSpectrum(false);

        setopenSnackErrMsg("Successfully created Spectrum ");
        setOpenSnack(true);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.message + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleUpdateSpectrum = () => {
    const spectrum_data = {
      roomId: roomDetails.roomId,
      spectrumControlId: spectrumNameAct.spectrumControlId,
    };
    updateSpectrum(roomDetails.roomId, spectrum_data)
      .then(function (response) {
        setOpenSpectrum(false);
        setopenSnackErrMsg("Successfully updated Spectrum ");
        setOpenSnack(true);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleRefresh = () => {
    window.location.reload(false);
  };

  const handleSpectrumNameText = (event) => {
    setSpectrumNameText(event.target.value);
  };

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 1 }}
      >
        <Typography
          sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
          variant="h5"
          component="div"
        >
          Home
        </Typography>

        {/* <ToggleButtonGroup
          size="medium"
          color="primary"
          exclusive
          value={activeRoom}
          onChange={handleRoomChange}
          aria-label="text alignment"
          // sx={{ background: "#EBEBE4" }}
        >
          {roomNames.map((rooms, index) => (
            <ToggleButton value={rooms} aria-label={rooms.roomLable}>
              {rooms.roomName}
            </ToggleButton>
          ))}
        </ToggleButtonGroup> */}

        <Paper
          elevation={0}
          sx={{
            display: "flex",
            border: (theme) => `2px solid ${theme.palette.divider}`,
            flexWrap: "wrap",
            // p: 1,
          }}
        >
          <StyledToggleButtonGroup
            size="small"
            value={activeRoom}
            exclusive
            onChange={handleRoomChange}
            aria-label="text alignment"
          >
            {roomNames.map((rooms, index) => (
              <ToggleButton value={rooms} aria-label={rooms.roomLable}>
                {rooms.roomName}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
          {/* <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {roomNames.map((rooms, index) => (
              <Grid item xs>
                <Button variant="outlined" size="small">
                  Room
                </Button>
              </Grid>
            ))}
          </Grid> */}
        </Paper>

        <Button
          variant="contained"
          size="small"
          onClick={handleRefresh}
          disabled={!roles.includes("add-spectrum")}
        >
          <RefreshIcon />
          &nbsp;Refresh
        </Button>
      </Stack>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />
      <Stack
        direction="row"
        spacing={2}
        mb={2}
        justifyContent="center"
        alignItems="center"
      ></Stack>

      <Dialog open={open} onClose={handleCloseRoutines}>
        <DialogTitle>Create Routine</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseRoutines}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            size="small"
            id="routine_name"
            label="Routine Name"
            type="routine_name"
            fullWidth
            value={routineNameInput}
            onChange={handleRoutineNameChange}
            variant="outlined"
          />

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            mt={0.5}
          >
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 250 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={routineTime}
                    onChange={(newValue) => setRoutineTime(newValue)}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <ToggleButtonGroup
                color="primary"
                value={routineAction}
                exclusive
                size="large"
                align="center"
                justifyContent="center"
                onChange={handleActionChange}
                aria-label="Platform"
                // sx={{ mt: 1 }}
              >
                <ToggleButton value="ON">ON</ToggleButton>
                <ToggleButton value="OFF">OFF</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 250, mt: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose Room
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={roomNameRot}
                  label="Choose Room"
                  onChange={handleRoomChangeRoutine}
                >
                  {noRoomData ? (
                    <em>
                      <MenuItem value="NaNa">No Data Found</MenuItem>
                    </em>
                  ) : (
                    roomNames.map((rooms, index) => (
                      <MenuItem value={rooms}>{rooms.roomName}</MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 250, mt: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose Spectrum
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={spectrumNameRot}
                  label="Choose Spectrum"
                  onChange={handleSpectrumChange}
                >
                  {spectrumNamesRot.length < 1 ? (
                    <em>
                      <MenuItem value="">No Data Found</MenuItem>
                    </em>
                  ) : (
                    spectrumNamesRot.map((spectrumValue, index) =>
                      spectrumValue.status == 1 ? (
                        <MenuItem value={spectrumValue}>
                          {spectrumValue.spectrumName} <em>&nbsp;(Active) </em>
                        </MenuItem>
                      ) : (
                        <MenuItem value={spectrumValue}>
                          {spectrumValue.spectrumName}
                        </MenuItem>
                      )
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}></Grid>
          </Grid>
          <Box sx={{ ml: 2, mt: 1 }} fullWidth>
            <FormGroup aria-label="position" row align="center">
              {weekDays.map((day, index) => (
                <FormControlLabel
                  value={day}
                  control={<Checkbox />}
                  label={day}
                  onClick={(e) => handleWeekCheckbox(e, index)}
                  labelPlacement="end"
                />
              ))}
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRoutines}>Cancel</Button>
          <Button onClick={handleSaveRoutines}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSpectrum} onClose={handleSpectrumClose}>
        <DialogTitle sx={{ ml: 1, mt: 1 }}>Save Spectrum As</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleSpectrumClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            size="small"
            id="spectrum_name"
            label="Spectrum Name"
            type="spectrum_name"
            sx={{ width: 350 }}
            variant="outlined"
            value={spectrumNameText}
            onChange={handleSpectrumNameText}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            mt={0.5}
          >
            <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 1, mr: 1 }}>
          <Button onClick={handleSpectrumClose}>Cancel</Button>
          <Button onClick={handleNewSpectrum}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditName} onClose={handleEditClose}>
        <DialogTitle>Edit Name</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ minWidth: 250 }}>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="name"
              label="Name"
              type="name"
              fullWidth
              value={editedChannelName}
              onChange={handleLableChange}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSave}>Add</Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Paper elevation={2} sx={{ pr: 6, pl: 6, pb: 4, pt: 3 }}>
          <Stack
            direction="row"
            spacing={2}
            mb={1}
            mt={-0.5}
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel id="demo-simple-select-label">Spectrum</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={spectrumNameAct}
                label="Choose Schedule"
                size="small"
                onChange={handleChange}
              >
                {spectrumNames.map((spectrumValue, index) => (
                  <MenuItem value={spectrumValue}>
                    {spectrumValue.spectrumName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack
              direction="row"
              spacing={2}
              mb={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={lockRatio} onClick={changeRatioState} />
                  }
                  label="Set Ratio"
                />
              </FormGroup>
              <Button
                variant="contained"
                size="small"
                onClick={saveSpectrumData}
                disabled={spectrumSaveDisabled}
              >
                <SaveIcon />
                &nbsp;SAVE
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleSpectrumOpen}
                disabled={!roles.includes("add-spectrum")}
              >
                <SaveAsIcon />
                &nbsp;New Spectrum
              </Button>
              {/* <Button
                variant="contained"
                size="small"
                onClick={handleClickOpen}
                disabled={!roles.includes("add-routines")}
              >
                {" "}
                <ScheduleIcon /> &nbsp;Routines{" "}
              </Button> */}
            </Stack>
          </Stack>

          <Grid
            container
            spacing={{ xs: 2, md: 1 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {spectrum.map((value, index) => (
              <Grid item xs={4} sm={4} md={4} key={index}>
                {lockRatio == 1 && index == masterSpectrumIndex ? (
                  <div>
                    <Box
                      sx={{
                        width: 340,
                        border: 3,
                        borderRadius: 1,
                        borderColor: "warning.main",
                      }}
                    >
                      <Paper elevation={2} sx={{ p: 1.2 }}>
                        <Stack
                          direction="row"
                          spacing={2}
                          mt={-1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              id="input-slider"
                              color={
                                value.isActive
                                  ? "text.primary"
                                  : "text.disabled"
                              }
                              disabled={!value.isActive}
                            >
                              {value.channelName}
                            </Typography>
                            <IconButton
                              size="small"
                              aria-label="close"
                              color={value.isActive ? "inherit" : "disabled"}
                              disabled={!value.isActive}
                              onClick={(e) => editLableName(e, index)}
                            >
                              <EditIcon color="action" fontSize="small" />
                            </IconButton>
                          </Stack>

                          <Checkbox
                            checked={value.isActive}
                            onClick={(e) => changeSliderState(e, index)}
                          />
                        </Stack>

                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs>
                            <Slider
                              size="medium"
                              value={
                                typeof value.channelValue === "number"
                                  ? value.channelValue >= 100
                                    ? 100
                                    : Math.round(value.channelValue)
                                  : 0
                              }
                              onChange={(e) => handleSliderChange(e, index)}
                              onChangeCommitted={handleChangeComplete}
                              valueLabelDisplay="auto"
                              aria-labelledby="input-slider"
                              disabled={!value.isActive}
                              sx={{ mx: 1, mt: -1 }}
                            />
                          </Grid>
                          <Grid item>
                            <Input
                              value={
                                value.channelValue >= 100
                                  ? 100
                                  : Math.round(value.channelValue)
                              }
                              size="small"
                              onChange={(e) => handleInputChange(e, index)}
                              onBlur={handleBlur}
                              disabled={!value.isActive}
                              sx={{ ml: 1 }}
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 100,
                                type: "number",
                                "aria-labelledby": "input-slider",
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color={"primary.main"}
                          sx={{
                            fontWeight: "bold",
                            mt: -1,
                            mb: -1,
                          }}
                        >
                          Master Slider
                        </Typography>
                      </Paper>
                    </Box>
                  </div>
                ) : (
                  <div>
                    <Box
                      sx={{
                        width: 340,
                      }}
                    >
                      <Paper elevation={2} sx={{ p: 1.2 }}>
                        <Stack
                          direction="row"
                          spacing={2}
                          mt={-1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              id="input-slider"
                              color={
                                value.isActive
                                  ? "text.primary"
                                  : "text.disabled"
                              }
                              disabled={!value.isActive}
                            >
                              {value.channelName}
                            </Typography>
                            <IconButton
                              size="small"
                              aria-label="close"
                              color={value.isActive ? "inherit" : "disabled"}
                              disabled={!value.isActive}
                              onClick={(e) => editLableName(e, index)}
                            >
                              <EditIcon color="action" fontSize="small" />
                            </IconButton>
                          </Stack>

                          <Checkbox
                            checked={value.isActive}
                            onClick={(e) => changeSliderState(e, index)}
                          />
                        </Stack>

                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs>
                            <Slider
                              size="medium"
                              value={
                                typeof value.channelValue === "number"
                                  ? value.channelValue >= 100
                                    ? 100
                                    : Math.round(value.channelValue)
                                  : 0
                              }
                              onChange={(e) => handleSliderChange(e, index)}
                              onChangeCommitted={handleChangeComplete}
                              valueLabelDisplay="auto"
                              aria-labelledby="input-slider"
                              disabled={!value.isActive}
                              sx={{ mx: 1, mt: -1 }}
                            />
                          </Grid>
                          <Grid item>
                            <Input
                              value={
                                value.channelValue >= 100
                                  ? 100
                                  : Math.round(value.channelValue)
                              }
                              size="small"
                              onChange={(e) => handleInputChange(e, index)}
                              onBlur={handleBlur}
                              disabled={!value.isActive}
                              sx={{ ml: 1 }}
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 100,
                                type: "number",
                                "aria-labelledby": "input-slider",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}
