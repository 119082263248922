import { useState, useEffect, Fragment } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import DevicesIcon from "@mui/icons-material/Devices";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import PaletteIcon from "@mui/icons-material/Palette";
import ScheduleIcon from "@mui/icons-material/Schedule";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ColorizeIcon from "@mui/icons-material/Colorize";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TuneIcon from "@mui/icons-material/Tune";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

import HomeScreen from "../screens/home";
import HomeScreenV1 from "../screens/home_v1";
import DeviceScreen from "../screens/devices";
import ColorSpectrum from "../screens/color_spectrum";
import Schedule from "../screens/schedule";
import UserScreen from "../screens/users";
import Settings from "../screens/settings";
import RoomManager from "../screens/rooms";
import AdminSpectrum from "../screens/admin_spectrum";
import Logo from "../logo/fluortronix-final.png";

import { getAlertFlag, getSpectrumData } from "../global/globalVariables";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import {
  getAuth,
  getDeviceStatus,
  preStatus,
  resetUserPassword,
  resetSpectrum,
  getLatestFirmwareVersion,
} from "../service/service_handler";
import Alerts from "../screens/alerts";
import UpdateManager from "../screens/updates";
import UpdateManagerAdmin from "../screens/updates_admin";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const pageList = ["Home", "Devices"];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [menuData, setMenuData] = useState("");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const settings = ["Profile", "Logout"];
  const [roles, setRoles] = useState([]);
  const [auth, setAuth] = useState(true);
  const [userName, setUserName] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [devices, setDevices] = useState([]);
  const [openReset, setOpenReset] = useState(false);
  const [passwordTest, setPasswordText] = useState("");
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    // getAuth()
    //   .then(function (response) {})
    //   .catch((error) => {
    //     console.log("service-error", error.respons);
    //     if (error.response.status == 401) {
    //     }
    //     if (!error.response) {
    //       console.log("Network Error");
    //     } else {
    //       console.log("Service Error");
    //     }
    //   });

    // preStatus()
    //   .then(function (response) {})
    //   .catch((error) => {
    //     console.log("service-error", error.respons);
    //     if (error.response.status == 401) {
    //     }
    //     if (!error.response) {
    //       console.log("Network Error");
    //     } else {
    //       console.log("Service Error");
    //     }
    //   });

    getLatestFirmwareVersion()
      .then(function (response) {
        setUpdateData(response.data.firmwareData);
      })
      .catch((error) => {
        console.log("service-error", error.respons);
        if (error.response.status == 401) {
        }
        if (!error.response) {
          console.log("Network Error");
        } else {
          console.log("Service Error");
        }
      });

    // setTimeout(function () {
    //   getDeviceStatus()
    //     .then(function (response) {
    //       setNotifications(response.data.devices);
    //       if (response.data.devices.length > 0) {
    //         setOpenNotification(true);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("service-error", error.respons);
    //       if (error.response.status == 401) {
    //       }
    //       if (!error.response) {
    //         console.log("Network Error");
    //       } else {
    //         console.log("Service Error");
    //       }
    //     });
    // }, 30000);

    setTimeout(function () {
      getDeviceStatus()
        .then(function (response) {
          const actieDeviceList = [];

          if (response.data.devices.length > 0) {
            const now = dayjs();
            response.data.devices.forEach((device) => {
              const lastOnline = dayjs(device.updatedAt);
              const diffInSeconds = now.diff(lastOnline, "second");

              if (diffInSeconds < 60) {
                device.lastOnline = `${diffInSeconds} sec ago.`;
                // console.log(`Last online ${diffInSeconds} seconds ago.`);
              } else if (diffInSeconds < 3600) {
                device.lastOnline = `${Math.floor(
                  diffInSeconds / 60
                )} min ago.`;
                // console.log(
                //   `Last online ${Math.floor(diffInSeconds / 60)} minutes ago.`
                // );
              } else if (diffInSeconds < 86400) {
                device.lastOnline = `${Math.floor(
                  diffInSeconds / 3600
                )} hrs ago.`;
                // console.log(
                //   `Last online ${Math.floor(diffInSeconds / 3600)} hours ago.`
                // );
              } else {
                device.lastOnline = `${Math.floor(
                  diffInSeconds / 86400
                )} days ago.`;
                // console.log(
                //   `Last online ${Math.floor(diffInSeconds / 86400)} days ago.`
                // );
              }
              if (diffInSeconds >= 50) {
                device.deviceStatus = 0;
                actieDeviceList.push(device);
              }
            });

            setOpenNotification(true);
            setNotifications(actieDeviceList);
          }
        })
        .catch((error) => {
          console.log("service-error", error.respons);
          if (error.response.status == 401) {
          }
          if (!error.response) {
            console.log("Network Error");
          } else {
            console.log("Service Error");
          }
        });
    }, 3000);

    if (localStorage.getItem("token")) {
      const jwtData = jwtDecode(localStorage.getItem("token"));
      setRoles(jwtData.roles);
      setUserName(jwtData.sub);
      console.log(
        "roles: " + JSON.stringify(jwtDecode(localStorage.getItem("token")))
      );
      if (!jwtData.roles.includes("super-admin")) {
        // console.log("includes", jwtData.roles);
        changeMenuName("home");
      } else {
        // console.log("not includes");
        changeMenuName("settings");
      }
    } else {
      console.log("not loged in");
      window.location.replace("/app-login");
    }
  }, []);

  const changeMenuName = (menuName) => {
    if (menuName != "home" && getAlertFlag()) {
      const confirmNavigation = window.confirm(
        "Your changes will be lost!\nAre you sure you want to leave?"
      );
      if (confirmNavigation) {
        const specData = getSpectrumData();
        console.log("sidebar spectrum " + JSON.stringify(specData));
        resetSpectrumData(specData);
        setMenuData(menuName);
      }
    } else {
      setMenuData(menuName);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNotificationClose = () => {
    setOpenNotification(!openNotification);
  };

  const handleOpenNotification = () => {
    setOpenNotification(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("act_room");
    setAnchorElUser(null);
    window.location.replace("/app-login");
  };

  const handleCloseResetPopup = () => {
    setPasswordText("");
    setOpenReset(!openReset);
  };

  const handleConfirmedReset = () => {
    const user_data = {
      userName: userName,
      userPassword: passwordTest,
    };
    if (userName != "" && passwordTest != "") {
      resetUserPassword(user_data)
        .then(function (response) {
          setPasswordText("");
          setOpenReset(!openReset);
          setopenSnackErrMsg("Password Reset Successfully");
          setOpenSnack(true);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid Data!");
      setOpenSnack(true);
    }
  };

  const handlePasswordText = (event) => {
    setPasswordText(event.target.value);
  };

  const resetSpectrumData = (data) => {
    resetSpectrum(data.roomId, data.spectrumId)
      .then(function (response) {})
      .catch((error) => {
        console.log("service-error", error.respons);
        if (error.response.status == 401) {
        }
        if (!error.response) {
          console.log("Network Error");
        } else {
          console.log("Service Error");
        }
      });
  };

  const handleChangePassword = () => {
    setOpenReset(true);
  };

  const snackAction = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Dialog open={openNotification} onClose={handleNotificationClose}>
        <DialogTitle sx={{ color: "error.main" }}>Active Alerts</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleNotificationClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 550 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Device Name</TableCell>
                  {/* <TableCell align="right">Device Id</TableCell> */}
                  <TableCell align="right">Temperature</TableCell>
                  <TableCell align="right">Fan</TableCell>
                  <TableCell align="right">Status</TableCell>
                  {/* <TableCell align="right">Last Online</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications.map((row) => (
                  <TableRow
                    key={row.deviceName}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.deviceName}
                    </TableCell>
                    {/* <TableCell align="right">{row.deviceSerialNo}</TableCell> */}
                    <TableCell align="right">
                      {row.deviceTemperature < -5
                        ? "NA"
                        : row.deviceTemperature + " ° C"}
                    </TableCell>
                    <TableCell align="right">{row.fanSpeed} %</TableCell>
                    <TableCell align="right">
                      {row.deviceStatus == 1 ? (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            bgcolor: "success.light",
                            "&:hover": {
                              bgcolor: "success.light",
                            },
                          }}
                        >
                          ONLINE
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            bgcolor: "grey",
                            "&:hover": {
                              bgcolor: "grey",
                            },
                          }}
                        >
                          OFFLINE
                        </Button>
                      )}
                    </TableCell>
                    {/* <TableCell align="right">{row.lastOnline}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />

      <Dialog open={openReset} onClose={handleCloseResetPopup}>
        <DialogTitle>Reset Password</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseResetPopup}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ minWidth: 250 }}>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="user_name"
              label="User Name"
              type="user_name"
              fullWidth
              value={userName}
              // onChange={handleUserName}
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="password"
              label="New Password"
              type="password"
              fullWidth
              value={passwordTest}
              onChange={handlePasswordText}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetPopup}>Cancel</Button>
          <Button onClick={handleConfirmedReset}>Reset</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} sx={{ background: "#d3d3d3" }}>
          <Toolbar>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton> */}
            <Box
              component="img"
              sx={{
                height: 45,
                mr: 2,
                ml: 2,
                background: "#d3d3d3",
              }}
              alt="Flu."
              src={Logo}
            />
            <Box display="flex" flexGrow={1} sx={{ mr: 4, mt: 1 }}>
              {/* <Typography
                variant="h5"
                fontWeight={"bold"}
                noWrap
                color={"#0d2864"}
                sx={{
                  mr: 2,
                }}
              >
                IoT Control Dashboard
              </Typography> */}
            </Box>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              noWrap
              color={"#326abb"}
              sx={{
                mr: 2,
              }}
            >
              IoT Control Dashboard
            </Typography>
            {/* <Box>
            <Tooltip title="User Profile">
              <IconButton on Click={handleOpenUserMenu} sx={{ p: 0 }}>
                <PersonIcon fontSize="large" color="white"></PersonIcon>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="user_name" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Admin User</Typography>
              </MenuItem>

              <MenuItem key="logout" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box> */}

            {auth && (
              <div>
                <IconButton
                  sx={{ mr: 2, color: "#262626" }}
                  // onClick={handleOpenNotification}
                >
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={!updateData.updateAvailable}
                  >
                    <CloudSyncIcon fontSize="large" color="inherit" />
                  </Badge>
                </IconButton>
                <IconButton
                  sx={{ mr: 2, color: "#404040" }}
                  onClick={handleOpenNotification}
                >
                  <Badge color="secondary" badgeContent={notifications.length}>
                    <NotificationsIcon fontSize="large" color="inherit" />
                  </Badge>
                </IconButton>
                <Tooltip title={userName}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenUserMenu}
                    color="black"
                  >
                    <AccountCircle fontSize="large" color="inherit" />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>{userName}</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  <MenuItem onClick={handleChangePassword}>
                    Change Password
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <List>
            {/* {pageList.map((text, index) => ( */}
            {!roles.includes("super-admin") ? (
              <div>
                <ListItem
                  key="Home"
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => changeMenuName("home")}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Home">
                        <HomeIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      primary="Home"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </div>
            ) : (
              <div>
                <ListItem
                  key="Admin Home"
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => changeMenuName("settings")}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Home">
                        <HomeIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      primary="Admin Settings"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  key="Admin Spectrum"
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => changeMenuName("admin_spectrum")}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Spectrum Manager">
                        <TuneIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      primary="Admin Spectrum"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </div>
            )}
            <ListItem
              key="Color Spectrum"
              disablePadding
              sx={{ display: "block" }}
              onClick={() => changeMenuName("color_spectrum")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Spectrum Manager">
                    <ColorizeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary="Color Spectrum"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              key="Room Manager"
              disablePadding
              sx={{ display: "block" }}
              onClick={() => changeMenuName("room_manager")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Room Manager">
                    <MeetingRoomIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary="Manage Room"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            {!roles.includes("super-admin") && (
              <ListItem
                key="Manage Schedule"
                disablePadding
                sx={{ display: "block" }}
                onClick={() => changeMenuName("schedule")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Schedule Manager">
                      <MoreTimeIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary="Routines"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem
              key="Devices"
              disablePadding
              sx={{ display: "block" }}
              onClick={() => changeMenuName("devices")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Device Manager">
                    <DevicesIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary="Devices"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            {/* ))} */}
          </List>
          <Divider />
          <List>
            {roles.includes("add-user") && (
              <ListItem
                key="Manage Users"
                disablePadding
                sx={{ display: "block" }}
                onClick={() => changeMenuName("user_screen")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="User Management">
                      <GroupIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary="Manage Users"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem
              key="Manage Alerts"
              disablePadding
              sx={{ display: "block" }}
              onClick={() => changeMenuName("alerts_screen")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Alert Management">
                    <WarningAmberIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary="Manage Alerts"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            {roles.includes("super-admin") && (
              <ListItem
                key="Manage Updates"
                disablePadding
                sx={{ display: "block" }}
                onClick={() => changeMenuName("updates_admin")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Manage Updates">
                      <CloudSyncIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary="Manage Updates"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {roles.includes("manage-updates") && (
              <ListItem
                key="Manage Updates"
                disablePadding
                sx={{ display: "block" }}
                onClick={() => changeMenuName("updates_screen")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Updates Manager">
                      <CloudSyncIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary="Manage Updates"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {menuData == "home" && <HomeScreen />}
          {menuData == "admin_spectrum" && <AdminSpectrum />}
          {menuData == "devices" && <DeviceScreen />}
          {menuData == "color_spectrum" && <ColorSpectrum />}
          {menuData == "schedule" && <Schedule />}
          {menuData == "user_screen" && <UserScreen />}
          {menuData == "settings" && <Settings />}
          {menuData == "room_manager" && <RoomManager />}
          {menuData == "alerts_screen" && <Alerts />}
          {menuData == "updates_screen" && <UpdateManager />}
          {menuData == "updates_admin" && <UpdateManagerAdmin />}
        </Box>
      </Box>
    </div>
  );
}
