import { useState, useEffect, Fragment, React } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SyncIcon from "@mui/icons-material/Sync";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";
import RefreshIcon from "@mui/icons-material/Refresh";
import { jwtDecode } from "jwt-decode";

import {
  getRooms,
  getDevices,
  getLatestFirmwareVersion,
  updateOrgFirmwareUpdate,
} from "../service/service_handler";

export default function UpdateManager() {
  const [roomName, setRoomName] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [spectrums, setspectrums] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [roles, setRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [devices, setDevices] = useState([]);
  const [openUpdates, setOpenUpdates] = useState(false);
  const [openUpdatesLoader, setOpenUpdatesLoader] = useState(false);
  const [updateData, setUpdateData] = useState({});

  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const jwtdecoded = jwtDecode(localStorage.getItem("token"));
      const rolesarr = jwtdecoded.roles;
      rolesarr.pop();
      setRoles(rolesarr);
      setIsAdmin(jwtdecoded.orgCode == "SUPER_ADMIN" ? true : false);
    }

    getLatestFirmwareVersion()
      .then(function (response) {
        setUpdateData(response.data.firmwareData);
      })
      .catch((error) => {
        console.log("service-error", error.respons);
        if (error.response.status == 401) {
        }
        if (!error.response) {
          console.log("Network Error");
        } else {
          console.log("Service Error");
        }
      });

    getDevices()
      .then(function (response) {
        setDevices(response.data.devices);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  }, []);

  const updateDevices = () => {
    getDevices()
      .then(function (response) {
        setDevices(response.data.devices);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const updateFirmwareData = () => {
    getLatestFirmwareVersion()
      .then(function (response) {
        setUpdateData(response.data.firmwareData);
      })
      .catch((error) => {
        console.log("service-error", error.respons);
        if (error.response.status == 401) {
        }
        if (!error.response) {
          console.log("Network Error");
        } else {
          console.log("Service Error");
        }
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleUpdatesClose = () => {
    setOpenUpdates(!openUpdates);
  };

  const handleOpenUpdates = () => {
    setOpenUpdates(true);
    setOpenUpdatesLoader(false);
  };

  const confirmUpdate = () => {
    setOpenUpdatesLoader(true);
    // call update trigger
    const update_firmware_req = {
      type: "UPDATE_ALL",
    };
    updateOrgFirmwareUpdate(update_firmware_req)
      .then(function (response) {
        updateFirmwareData();
        setopenSnackErrMsg(
          "Successfully updated firmware " + response.data.code
        );
        setOpenSnack(true);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });

    setTimeout(function () {
      setOpenUpdates(false);
      setOpenUpdatesLoader(true);
      console.log("closed update box");
      // reload table call
    }, 25000);
  };

  const updateOrRetryUpdate = (device) => {
    setOpenUpdatesLoader(true);
    // call update trigger
    const update_firmware_req = {
      type: device.deviceId,
    };
    console.log("Device Details: " + update_firmware_req);
    updateOrgFirmwareUpdate(update_firmware_req)
      .then(function (response) {
        updateFirmwareData();
        setopenSnackErrMsg(
          "Successfully updated firmware, check status affter 5 mins "
        );
        setOpenSnack(true);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });

    setTimeout(function () {
      setOpenUpdates(false);
      setOpenUpdatesLoader(true);
      console.log("closed update box");
      // reload table call
    }, 25000);
  };

  const handleRefresh = () => {
    updateDevices();
  };

  const cancelUpdate = () => {
    setOpenUpdates(false);
  };
  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0 }}
      >
        <Typography
          sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
          variant="h5"
          component="div"
        >
          Firmware Update Manager
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            // backgroundColor: "#38bdf81a",
            backgroundColor: [
              updateData.updateAvailable ? "#38bdf81a" : "#dcfce7",
            ],
            width: "auto",
            height: "auto",
            paddingY: "4px",
            paddingX: "30px",
            // border: "2px solid #38bdf81a",
            border: [
              updateData.updateAvailable
                ? "2px solid #38bdf81a"
                : "2px solid #bbf7d0",
            ],
            borderRadius: "10px",
          }}
        >
          {updateData.updateAvailable ? (
            <Typography
              variant="subtitle2"
              color={"#0284C7"}
              fontWeight={"bold"}
            >
              New Update Available ! (v{updateData.newFirmwareVersion})
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              color={"#22c55e"}
              fontWeight={"bold"}
            >
              Already Up to date (v{updateData.orgCurrentFirmwareVersion})
            </Typography>
          )}
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ mt: 0 }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handleOpenUpdates}
            disabled={!updateData.updateAvailable}
          >
            <CloudUploadIcon />
            &nbsp;Update Now
          </Button>
        </Stack>
      </Stack>

      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />

      <Dialog open={openUpdates} onClose={handleUpdatesClose}>
        <DialogTitle>Updates</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleUpdatesClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent minWidth={200}>
          {openUpdatesLoader ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ minWidth: 350 }}
            >
              <Alert sx={{ mt: -2, mb: 3 }} severity="info">
                If any device updates fails, please retry manually.
              </Alert>
              <CircularProgress size={50} />
              <Typography
                variant="subtitle2"
                sx={{ mt: 3 }}
                color="text.secondary"
                fontWeight={"bold"}
              >
                Update in progress, it might take few minutes.
              </Typography>
            </Box>
          ) : (
            <Box sx={{ minWidth: 350 }}>
              <Alert sx={{ mt: -2, mb: 1 }} severity="warning">
                Warning: Make sure all ESP are online while updating !
              </Alert>
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Firmware Update Details:
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ mt: 2 }}
                fontWeight={"bold"}
              >
                Previous Version: {updateData.orgPreviousFirmwareVersion}
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={"bold"}
                color={"#059669"}
              >
                Current Version: {updateData.orgCurrentFirmwareVersion}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ mb: 1, mr: 1 }}>
          {!openUpdatesLoader && (
            <div>
              <Button onClick={cancelUpdate}>Cancel</Button>
              <Button onClick={confirmUpdate}>Update</Button>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Typography variant="subtitle1" sx={{ mt: 4 }} fontWeight={"bold"}>
        Last Update Details:
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ mt: 1 }}
        fontWeight={"bold"}
        color="text.secondary"
      >
        Previous Version: {updateData.orgPreviousFirmwareVersion}
      </Typography>
      <Typography variant="subtitle2" fontWeight={"bold"} color={"#059669"}>
        Current Version: {updateData.orgCurrentFirmwareVersion}
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 1 }}
      >
        <Typography
          variant="subtitle1"
          sx={{ mt: 3, mb: 1 }}
          fontWeight={"bold"}
        >
          Device Status:
        </Typography>

        <Button variant="contained" size="small" onClick={handleRefresh}>
          <RefreshIcon />
          &nbsp;Refresh
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Device Name</TableCell>
              <TableCell align="right">Device Id</TableCell>
              <TableCell align="right">Room Name</TableCell>
              <TableCell align="right">Organization</TableCell>
              <TableCell align="right">Device Firmware</TableCell>
              <TableCell align="right">Feedback Summary</TableCell>
              <TableCell align="right">Device Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.map((row) => (
              <TableRow
                key={row.deviceName}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell style={{ width: "90px" }} component="th" scope="row">
                  {row.deviceName}
                </TableCell>
                <TableCell style={{ width: "90px" }} align="right">
                  {row.deviceSerialNo}
                </TableCell>
                <TableCell style={{ width: "90px" }} align="right">
                  {row.room ? row.room.roomName : "Not Added"}
                </TableCell>
                <TableCell style={{ width: "90px" }} align="right">
                  {row.orgCode}
                </TableCell>
                <TableCell style={{ width: "100px" }} align="right">
                  {row.firmwareVersion}
                </TableCell>
                <TableCell style={{ width: "150px" }} align="right">
                  {row.feedbackSummary}
                </TableCell>
                <TableCell style={{ width: "90px" }} align="right">
                  {row.deviceStatus == 1 ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "success.light",
                        "&:hover": {
                          bgcolor: "success.light",
                        },
                      }}
                    >
                      ONLINE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "grey",
                        "&:hover": {
                          bgcolor: "grey",
                        },
                      }}
                    >
                      OFFLINE
                    </Button>
                  )}
                </TableCell>

                <TableCell style={{ width: "100px" }} align="center">
                  {/* {parseFloat(updateData.newFirmwareVersion) >
                    parseFloat(row.firmwareVersion) && (
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<ReplayIcon />}
                    >
                      Update
                    </Button>
                  )} */}

                  {parseFloat(updateData.newFirmwareVersion) ==
                    parseFloat(row.firmwareVersion) && (
                    <Button
                      variant="contained"
                      size="small"
                      disabled={true}
                      startIcon={<ReplayIcon />}
                    >
                      Update
                    </Button>
                  )}

                  {parseFloat(updateData.newFirmwareVersion) >
                    parseFloat(row.firmwareVersion) && (
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<ReplayIcon />}
                      onClick={() => updateOrRetryUpdate(row)}
                    >
                      {row.updateSuccess == 0 ? "Retry Update" : "Update"}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
