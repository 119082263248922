import { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import Switch from "@mui/material/Switch";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  getRooms,
  getRoutinesByRoomId,
  createRoutine,
  getAllSpectrum,
  deleteRourines,
  updateRoutine,
} from "../service/service_handler";

function createData(
  spectrum_name,
  from_date,
  to_date,
  is_primary,
  updated_by,
  updated_on,
  actions
) {
  return {
    spectrum_name,
    from_date,
    to_date,
    is_primary,
    updated_by,
    updated_on,
    actions,
  };
}

export default function Schedule() {
  const [roomName, setRoomName] = useState("");
  const [mainRoomName, setMainRoomName] = useState("");
  const [roomNames, setRoomNames] = useState([]);
  const [spectrumName, setSpectrumName] = useState("");
  const [spectrumNames, setSpectrumNames] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [noRoomData, setNoRoomData] = useState(false);
  const [noSpectrumData, setNoSpectrumData] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [routineAction, setRoutineAction] = useState("1");
  const [routineNameInput, setRoutineNameInput] = useState("");
  const [routineTime, setRoutineTime] = useState(dayjs(new Date()));
  const [routines, setRoutines] = useState([]);
  const [noData, setNoData] = useState(false);
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [weekValues, setWeekValues] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [spectrumNameDisabled, setSpectrumNameDisabled] = useState(false);
  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  const timezone = require("dayjs/plugin/timezone");
  dayjs.extend(timezone);

  useEffect(() => {
    getRooms()
      .then(function (response) {
        setRoomNames(response.data.rooms);
        // setRoomName(response.data.rooms[0]);
        setMainRoomName(response.data.rooms[0]);
        getRoutinesByRoomId(response.data.rooms[0].roomId)
          .then(function (response) {
            setRoutines(response.data.routines);
          })
          .catch((error) => {
            console.log("service-error", error);
            if (!error.response) {
              setopenSnackErrMsg("Network error please try again");
              setOpenSnack(true);
              setNoData(true);
            } else {
              setopenSnackErrMsg(
                error.response.data.code + " - Please try again later"
              );
              setOpenSnack(true);
              setNoData(true);
            }
          });
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoRoomData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoRoomData(true);
        }
      });
    getAllSpectrum()
      .then(function (response) {
        console.log(response.data.spectrums);
        setSpectrumNames(response.data.spectrums);
        setSpectrumName(response.data.spectrums[0]);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          noSpectrumData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoSpectrumData(true);
        }
      });
  }, []);

  const handleRoomChange = (event) => {
    setRoomName(event.target.value);
    // getAllSpectrum()
    //   .then(function (response) {
    //     console.log(response.data.spectrums);
    //     setRoomName(event.target.value);
    //     // setSpectrumNames(response.data.spectrums);
    //     // setSpectrumName(response.data.spectrums[0]);
    //   })
    //   .catch((error) => {
    //     console.log("service-error", error);
    //     if (!error.response) {
    //       setopenSnackErrMsg("Network error please try again");
    //       setOpenSnack(true);
    //       noSpectrumData(true);
    //     } else {
    //       setopenSnackErrMsg(
    //         error.response.data.code + " - Please try again later"
    //       );
    //       setOpenSnack(true);
    //       setNoSpectrumData(true);
    //     }
    //   });
  };

  const handleMainRoomChange = (event) => {
    getRoutinesByRoomId(event.target.value.roomId)
      .then(function (response) {
        setRoutines(response.data.routines);
        setMainRoomName(event.target.value);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleSpectrumChange = (event) => {
    setSpectrumName(event.target.value);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleClose = () => {
    setRoutineTime(dayjs(new Date()));
    setRoutineNameInput("");
    setRoutineAction(1);
    setRoomName("");
    setSpectrumName("");
    setWeekValues([0, 0, 0, 0, 0, 0, 0]);
    setOpen(!open);
  };

  const handleSave = () => {
    // var utc = require("dayjs/plugin/utc");
    // dayjs.extend(utc);
    // console.log("timestamp: " + dayjs().unix());
    // console.log("timestamp: " + Math.floor(Date.now() / 1000));

    // console.log("name" + routineNameInput);
    // console.log(
    //   "time " + dayjs.utc(routineTime).format().split("T")[1].split("Z")[0]
    // );
    // console.log("rot: " + dayjs(routineTime).format());
    // console.log(routineAction);

    // console.log(roomName);
    // console.log("Rot Time: " + dayjs(routineTime).unix());
    const rotTime = dayjs(routineTime).unix();
    const nowTime = dayjs(new Date()).unix();
    // const calcTime = rotTime < nowTime ? rotTime + 86400 : rotTime;
    // console.log("Now Time: " + nowTime);
    // console.log("Calc TIme: " + calcTime);
    // console.log("Now Time1: " + nowTime);
    // console.log(weekValues);
    if (routineNameInput != "" && roomName && spectrumName) {
      const routine_data = {
        routineName: routineNameInput,
        action: parseInt(routineAction),
        daysInWeek: weekValues.toString(),
        spectrumId: spectrumName.spectrumControlId,
        spectrumName: spectrumName.spectrumName,
        // actionTime: dayjs(routineTime).format().split("T")[1].split("+")[0],
        // actionTime: dayjs(routineTime).unix(),
        actionTime: rotTime < nowTime ? rotTime + 86400 : rotTime,
        roomId: roomName.roomId,
      };
      createRoutine(routine_data)
        .then(function (response) {
          setOpen(!open);
          updateRoutineData();
          setopenSnackErrMsg("Successfully Created routine");
          setOpenSnack(true);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid data to continue");
      setOpenSnack(true);
    }
  };

  const handleRoutineNameChange = (event) => {
    setRoutineNameInput(event.target.value);
  };

  const handleRoutineTimeChange = (event) => {
    console.log(event.target.value);
    setRoutineTime(event.target.value);
  };

  const handleWeekCheckbox = (event, index) => {
    setWeekValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = 1;
      return newValues;
    });
    console.log(weekValues);
    // setRoutineTime(event.target.value);
  };

  const handleClickOpenDelete = (row) => {
    setOpenDelete(true);
    setDeleteId(row.routineId);
  };

  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
    setDeleteId("");
  };

  const handleConfirmedDelete = () => {
    deleteRourines(deleteId)
      .then(function (response) {
        setopenSnackErrMsg("Successfully deleted user");
        setOpenSnack(true);
        updateRoutineData();
        setOpenDelete(false);
        setDeleteId("");
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const updateRoutineData = () => {
    getRoutinesByRoomId(mainRoomName.roomId)
      .then(function (response) {
        setRoutines(response.data.routines);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleSwitchChange = (row) => {
    row.status = !row.status;
    updateRoutine(row.routineId, row)
      .then(function (response) {
        updateRoutineData();
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const handleActionChange = (event, action) => {
    setRoutineAction(action);
    if (action == 1) {
      console.log("Action ON:" + action);
      setSpectrumNameDisabled(false);
      setSpectrumName(spectrumNames[0]);
    } else {
      console.log(
        "Action OFF:" +
          action +
          "Spectum Names: " +
          JSON.stringify(spectrumNames)
      );
      // const offSpectrum = spectrumNames.filter(
      //   (item) => item.spectrumName === "Spectrum Off"
      // );
      const offSpectrum = spectrumNames.findIndex(
        (obj) => obj.spectrumName === "Spectrum Off"
      );
      console.log("Off Spectrum:" + offSpectrum);
      setSpectrumName(spectrumNames[offSpectrum]);
      setSpectrumNameDisabled(true);
    }
  };

  const convertTime = (date) => {
    return new Date(date);
  };

  const formatUnixTimestamp = (unixTimestamp) => {
    // Convert Unix timestamp to milliseconds
    const timestampInMilliseconds = unixTimestamp * 1000;

    const dateObject = new Date(timestampInMilliseconds);

    // Format the time to "HH:mm"
    return dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Typography
        sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
        variant="h5"
        component="div"
      >
        Routines
      </Typography>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />
      <Stack
        direction="row"
        spacing={2}
        mb={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Button variant="contained" onClick={handleClose}>
          <AddIcon />
          CREATE ROUTINE
        </Button>
        <Stack
          direction="row"
          spacing={2}
          mb={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="demo-simple-select-label">Choose Room</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mainRoomName}
              label="Choose Room"
              onChange={handleMainRoomChange}
            >
              {noRoomData ? (
                <em>
                  <MenuItem value="NaNa">No Data Found</MenuItem>
                </em>
              ) : (
                roomNames.map((rooms, index) => (
                  <MenuItem value={rooms}>{rooms.roomName}</MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          {/* <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="demo-simple-select-label">
              Choose Spectrum
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Choose Spectrum"
              onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}
        </Stack>
      </Stack>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Routine</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            size="small"
            id="routine_name"
            label="Routine Name"
            type="routine_name"
            fullWidth
            value={routineNameInput}
            onChange={handleRoutineNameChange}
            variant="outlined"
          />

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            mt={0.5}
          >
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 250 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={routineTime}
                    onChange={(newValue) => setRoutineTime(newValue)}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <ToggleButtonGroup
                color="primary"
                value={routineAction}
                exclusive
                size="large"
                align="center"
                justifyContent="center"
                onChange={handleActionChange}
                aria-label="Platform"
                // sx={{ mt: 1 }}
              >
                <ToggleButton value="1">ON</ToggleButton>
                <ToggleButton value="0">OFF</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 250, mt: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose Room
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={roomName}
                  label="Choose Room"
                  onChange={handleRoomChange}
                >
                  {noRoomData ? (
                    <em>
                      <MenuItem value="NaNa">No Data Found</MenuItem>
                    </em>
                  ) : (
                    roomNames.map((rooms, index) => (
                      <MenuItem value={rooms}>{rooms.roomName}</MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 250, mt: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose Spectrum
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={spectrumName}
                  label="Choose Spectrum"
                  onChange={handleSpectrumChange}
                  disabled={spectrumNameDisabled}
                >
                  {noSpectrumData ? (
                    <em>
                      <MenuItem value="NaNa">No Data Found</MenuItem>
                    </em>
                  ) : (
                    spectrumNames.map((spectrumValue, index) =>
                      spectrumValue.status == 1 ? (
                        <MenuItem value={spectrumValue}>
                          {spectrumValue.spectrumName} <em>&nbsp;(Active) </em>
                        </MenuItem>
                      ) : (
                        <MenuItem value={spectrumValue}>
                          {spectrumValue.spectrumName}
                        </MenuItem>
                      )
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}></Grid>
          </Grid>
          <Box sx={{ ml: 2, mt: 1 }} fullWidth>
            <FormGroup aria-label="position" row align="center">
              {weekDays.map((day, index) => (
                <FormControlLabel
                  value={day}
                  control={<Checkbox />}
                  label={day}
                  onClick={(e) => handleWeekCheckbox(e, index)}
                  labelPlacement="end"
                />
              ))}
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please confirm to Delete ?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeletePopup}>No</Button>
          <Button onClick={handleConfirmedDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Routine Name</TableCell>
              <TableCell align="right">Mode</TableCell>
              <TableCell align="right">Days (S,M,T,W,T,F,S)</TableCell>
              <TableCell align="right">Spectrum Name</TableCell>
              <TableCell align="right">Action Time</TableCell>
              <TableCell align="right">Created At</TableCell>
              <TableCell align="right">Updated At</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {routines.map((row) => (
              <TableRow
                key={row.routineName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.routineName}
                </TableCell>
                <TableCell align="right">
                  {row.action == 1 ? "ON" : "OFF"}
                </TableCell>
                <TableCell align="right">{row.daysInWeek}</TableCell>
                <TableCell align="right">{row.spectrumName}</TableCell>
                <TableCell align="right">
                  {dayjs(row.actionTime * 1000).format("LT")}
                  {/* {formatUnixTimestamp(row.actionTime)} */}
                </TableCell>
                <TableCell align="right">
                  {dayjs(row.createdAt).format("lll")}
                </TableCell>
                <TableCell align="right">
                  {dayjs(row.updatedAt).format("lll")}
                </TableCell>
                {/* <TableCell align="right">
                  {row.status == 1 ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "success.light",
                        "&:hover": {
                          bgcolor: "success.light",
                        },
                      }}
                    >
                      ACTIVE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "grey",
                        "&:hover": {
                          bgcolor: "grey",
                        },
                      }}
                    >
                      INACTIVE
                    </Button>
                  )}
                </TableCell> */}

                <TableCell align="right">
                  <Switch
                    checked={row.status}
                    onClick={() => handleSwitchChange(row)}
                  />
                </TableCell>
                <TableCell align="right">
                  {
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleClickOpenDelete(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
