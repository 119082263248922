import axios from "axios";
// import { useHistory } from "react-router-dom";
// const navigate = useNavigate();
// const history = useHistory();

const axiosClient = axios.create({
  // baseURL: "https://3.109.212.182:8084",
  baseURL: "https://api.fluortronixconnect.com",
  // baseURL: "http://localhost:8084",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    console.log("service-token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Unauthorized, redirect to login page
      localStorage.removeItem("token"); // Clear token
      // navigate("/login"); // Redirect to login page using React Router
      // history.push("/login");
      window.location.href = "/app-login";
      // Alternatively, use window.location.href = '/login'; for a full page reload
    }
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    console.log("error:" + res);
    // if (res.status == 401) {
    //   // window.location.href = "https://example.com/login";
    // }
    // console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

// axiosClient.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     let res = error.response;
//     if (res.status == 401) {
//       window.location.href = "https://example.com/login";
//     }
//     console.error("Looks like there was a problem. Status Code: " + res.status);
//     return Promise.reject(error);
//   }
// );

export default axiosClient;
