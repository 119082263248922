import { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { getLogonToken, resetPassword } from "../service/service_handler";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [passwordTest, setPasswordText] = useState("");
  const [passwordCnfTest, setPasswordCnfText] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [noData, setNoData] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleUserName = (event) => {
    setUserName(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordText = (event) => {
    setPasswordText(event.target.value);
  };

  const handlePasswordCnfText = (event) => {
    setPasswordCnfText(event.target.value);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleLogin = (event) => {
    // let history = useHistory()
    const login_data = {
      userName: userName,
      userPassword: passwordTest,
    };
    if (userName != "" && passwordTest != "") {
      getLogonToken(login_data)
        .then(function (response) {
          // setopenSnackErrMsg("Successfully Added device " + response.data.code);
          // setOpenSnack(true);
          localStorage.setItem("token", response.data.token);
          console.log(localStorage.getItem("token"));
          // this.props.history.push("/dashboard");
          // return <Redirect to="/dashboard" />;
          navigate("/dashboard");
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(error.response.data.msg);
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid Data!");
      setOpenSnack(true);
    }
  };

  const handleResetPassword = (event) => {
    const reset_data = {
      userName: userName,
      email: email,
    };
    if (userName != "" && email != "") {
      resetPassword(reset_data)
        .then(function (response) {
          setopenSnackErrMsg("Success - " + response.data.message);
          setOpenSnack(true);
          setTimeout(() => {
            setEmail("");
            setShowReset(false);
          }, 1000);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(error.response.data.msg);
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid UserName/Email");
      setOpenSnack(true);
    }
  };

  const handleShowReset = (event) => {
    setShowReset(!showReset);
  };

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />

      {!showReset && (
        <div>
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            flexDirection={"column"}
            minHeight={300}
            borderRadius={3}
            boxShadow={5}
            width={350}
            margin="auto"
            marginTop={20}
            bgcolor="#EAEAEA"
          >
            <PersonIcon fontSize="large" />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="user_name"
              label="User Name"
              type="user_name"
              sx={{ mt: 3 }}
              value={userName}
              onChange={handleUserName}
              variant="outlined"
            />
            <TextField
              margin="dense"
              size="small"
              id="password"
              label="Password"
              type="password"
              sx={{ mt: 1 }}
              value={passwordTest}
              onChange={handlePasswordText}
              variant="outlined"
            />
            <Button size="medium" onClick={handleShowReset}>
              Forgot Password
            </Button>
            <Button
              variant="contained"
              size="medium"
              sx={{ mt: 2 }}
              onClick={handleLogin}
            >
              LOGIN
            </Button>
          </Box>
        </div>
      )}
      {showReset && (
        <div>
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            flexDirection={"column"}
            minHeight={300}
            borderRadius={3}
            boxShadow={5}
            width={380}
            margin="auto"
            marginTop={20}
            bgcolor="#EAEAEA"
          >
            <PersonIcon fontSize="large" />

            <Typography variant="subtitle2" marginTop={1}>
              New password will be sent to your registred email.
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="user_name"
              label="User Name"
              type="user_name"
              sx={{ mt: 3 }}
              value={userName}
              onChange={handleUserName}
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="email"
              label="Registred Email"
              type="email"
              sx={{ mt: 1 }}
              value={email}
              onChange={handleEmail}
              variant="outlined"
            />
            <Button
              variant="contained"
              size="medium"
              sx={{ mt: 2 }}
              onClick={handleResetPassword}
            >
              RESET
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
}
