import { useState, useEffect, Fragment, useRef } from "react";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import MoreVert from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
// import jwt from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";

import {
  getRooms,
  getSpectrum,
  updateSpectrum,
  getAllSpectrum,
  getSpectrumByRoomId,
  createRoutine,
  createSpectrum,
  updateSpectrumRoom,
  getAllSpectrumByRoomId,
} from "../service/service_handler";

const Input = styled(MuiInput)`
  width: 42px;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
      color: "primary",
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  background: "primry",
}));

const spectrum_controls = [
  {
    channelName: "Green",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Red",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Yellow",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Blue",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Pink",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Orange",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Channel",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Dummy 1",
    channelValue: 10,
    isActive: true,
  },
  {
    channelName: "Dummy 2",
    channelValue: 10,
    isActive: true,
  },
];

export default function AdminSpectrum() {
  const [open, setOpen] = useState(false);
  const [weekNames, setWeekNames] = useState("");
  const [activeRoom, setActiveRoom] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [roomName, setRoomName] = useState("");
  const [roomDetails, setRoomDetails] = useState({});
  const [weeks, setWeeks] = useState(() => []);
  const [value, setValue] = useState([]);
  const [spectrum, setSpectrum] = useState(() => []);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [exisingSchedule, setExisingSchedule] = useState("");
  const [checked, setChecked] = useState(true);
  const weeksName = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [isVisible, setIsVisible] = useState(false);
  const [lockRatio, setLockRatio] = useState(false);
  const [openSpectrum, setOpenSpectrum] = useState(false);
  const [routineNameInput, setRoutineNameInput] = useState("");
  const [routineAction, setRoutineAction] = useState("ON");
  const [routineTime, setRoutineTime] = useState(dayjs(new Date().getTime()));
  const [noRoomData, setNoRoomData] = useState(false);
  const [noSpectrumData, setNoSpectrumData] = useState(false);
  const [spectrumName, setSpectrumName] = useState("");
  const [spectrumNames, setSpectrumNames] = useState([]);
  const [spectrumNameAct, setSpectrumNameAct] = useState({});
  const [openEditName, setOpenEditName] = useState(false);
  const [editedChannelName, setEditedChannelName] = useState("");
  const [noData, setNoData] = useState(false);
  const [roles, setRoles] = useState([]);
  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [weekValues, setWeekValues] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [spectrumNameRot, setSpectrumNameRot] = useState("");
  const [spectrumNamesRot, setSpectrumNamesRot] = useState([]);
  const [roomNameRot, setRoomNameRot] = useState("");
  const [spectrumNameText, setSpectrumNameText] = useState("");
  const [lableData, setLableData] = useState({});

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setRoles(jwtDecode(localStorage.getItem("token")).roles);
      console.log(
        "roles: " + JSON.stringify(jwtDecode(localStorage.getItem("token")))
      );
    }
    // getSpectrumByRoomId()
    //   .then(function (response) {
    //     // setSpectrumNames(response.data.spectrums);
    //     setSpectrumNameAct(response.data.spectrums[0]);
    //     setRoomDetails(response.data.spectrums[0].room);
    //     setRoomName(response.data.spectrums[0].room.roomName);
    //     setSpectrum(response.data.spectrums[0].channelData);
    //     setLockRatio(response.data.spectrums[0].lockRatio);
    //   })
    //   .catch((error) => {
    //     console.log("service-error", error);
    //     if (!error.response) {
    //       setopenSnackErrMsg("Network error please try again");
    //       setOpenSnack(true);
    //       setNoData(true);
    //     } else {
    //       setopenSnackErrMsg(
    //         error.response.data.code + " - Please try again later"
    //       );
    //       setOpenSnack(true);
    //       setNoData(true);
    //     }
    //   });

    getAllSpectrumByRoomId()
      .then(function (response) {
        console.log(
          "spectrum-names: " + JSON.stringify(response.data.spectrums)
        );
        setSpectrumNames(response.data.spectrums);
        setSpectrumNameAct(response.data.spectrums[0]);
        // setRoomDetails(response.data.spectrums[0].room);
        // setRoomName(response.data.spectrums[0].room.roomName);
        setSpectrum(response.data.spectrums[0].channelData);
        setLockRatio(response.data.spectrums[0].lockRatio);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Networkk error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  }, []);

  const handleRoomChange = (e, val) => {
    console.log("toggle-val:" + val.spectrumControlId);
    getSpectrumByRoomId(val.roomId)
      .then(function (response) {
        // setSpectrumNames(response.data.spectrums);
        setSpectrumNameAct(response.data.spectrums[0]);
        setRoomDetails(response.data.spectrums[0].room);
        setRoomName(response.data.spectrums[0].room.roomName);
        setSpectrum(response.data.spectrums[0].channelData);
        setLockRatio(response.data.spectrums[0].lockRatio);
        setActiveRoom(val);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("No Data found");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });

    getAllSpectrumByRoomId(val.roomId)
      .then(function (response) {
        setSpectrumNames(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });

    // console.log("spectrum service" + response.data.spectrum);
    // setRoomDetails(response.data.spectrum);
    // setRoomName(response.data.spectrum.room.roomName);
    // setSpectrum(response.data.spectrum.channelData);
    // setLockRatio(response.data.spectrum.lockRatio);
    // setActiveRoom(val);
  };

  const handleWeeks = (e, val) => {
    console.log("week-name:" + val);
    setWeeks(val);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsVisible(false);
  };

  const handleSpectrumOpen = () => {
    console.log("spectrum " + JSON.stringify(spectrumNameAct));
    setOpenSpectrum(true);
    setIsVisible(false);
  };

  const handleClose = () => {
    setOpen(false);
    // setIsVisible(false);
    // !open ? setIsVisible(false) : setIsVisible(false);
  };

  const handleCloseRoutines = () => {
    setRoutineTime(dayjs(new Date()));
    setRoutineNameInput("");
    setRoutineAction(1);
    setRoomNameRot("");
    setSpectrumNameRot("");
    setWeekValues([0, 0, 0, 0, 0, 0, 0]);
    setOpen(!open);
  };

  const handleSpectrumClose = () => {
    setSpectrumNameText("");
    setOpenSpectrum(false);
    // !openSpectrum ? setIsVisible(false) : setIsVisible(false);
  };

  const handleChange = (event) => {
    setSpectrumNameAct(event.target.value);
  };

  const handleSliderChange = (e, index) => {
    if (roles.includes("edit-spectrum")) {
      if (lockRatio) {
        const ratios = spectrum.map((value, indexChange) =>
          value.isActive
            ? indexChange === index
              ? 1
              : value.channelValue / spectrum[index].channelValue || 1
            : value.channelValue
        );
        console.log("Ratio: " + ratios);
        setSpectrum(
          spectrum.map((value, index) => {
            if (value.isActive) {
              value.channelValue = parseFloat(e.target.value) * ratios[index];
              console.log("values:" + value);
              return value;
            } else {
              console.log("values:" + value);
              return value;
            }
          })
        );
      } else {
        const newValue = parseFloat(e.target.value);
        setSpectrum((prevValues) => {
          console.log(...prevValues);
          const newValues = [...prevValues];
          newValues[index].channelValue = newValue;
          return newValues;
        });
      }
    }
  };

  const handleInputChange = (event, index) => {
    if (roles.includes("edit-spectrum")) {
      if (lockRatio) {
        console.log("spectrum-group");
        const ratios = spectrum.map((value, indexChange) =>
          value.isActive
            ? indexChange === index
              ? 1
              : value.channelValue / spectrum[index].channelValue || 1
            : value.channelValue
        );
        console.log("Ratio: " + ratios);
        setSpectrum(
          spectrum.map((value, index) => {
            console.log(value);
            if (value.isActive) {
              value.channelValue =
                parseFloat(event.target.value) * ratios[index];
              return value;
            } else {
              return value;
            }
          })
        );
      } else {
        const newValue = parseFloat(event.target.value);
        setSpectrum((prevValues) => {
          console.log(...prevValues);
          const newValues = [...prevValues];
          newValues[index].channelValue = newValue;
          return newValues;
        });
      }
    }
  };

  const editLableName = (event, index) => {
    setOpenEditName(!openEditName);
    const edit_lable = {
      index: index,
      channelName: spectrum[index].channelName,
    };
    setEditedChannelName(spectrum[index].channelName);
    setLableData(edit_lable);
  };

  const changeSliderState = (event, index) => {
    if (roles.includes("edit-spectrum")) {
      const updateSpectrumData = {
        channelData: JSON.stringify(spectrum),
        lockRatio: lockRatio,
        roomId: roomDetails.roomId,
      };
      // console.log("updateSpectrumData: ")
      updateSpectrum(roomDetails.roomId, updateSpectrumData)
        .then(function (response) {
          console.log("update spectrum" + response.data.code);
          if (response.data.code === "SPECTRUM_UPDATE_FAIL") {
            setopenSnackErrMsg("Failed to update Spectrum Data");
            setOpenSnack(true);
          } else {
            setopenSnackErrMsg("Successfully updated spectrum data");
            setOpenSnack(true);
          }
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
          }
        });

      setSpectrum((prevValues) => {
        const newValues = [...prevValues];
        newValues[index].isActive = !newValues[index].isActive;
        newValues[index].channelValue = 1;
        return newValues;
      });
    }
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  const handleChangeComplete = () => {
    //here you will make your api call
    console.log("Change event completed");
    const updateSpectrumData = {
      channelData: JSON.stringify(spectrum),
      lockRatio: lockRatio,
      roomId: spectrumNameAct.roomId,
    };

    updateSpectrum(spectrumNameAct.spectrumControlId, updateSpectrumData)
      .then(function (response) {
        console.log("update spectrum" + response.data.code);
        if (response.data.code === "SPECTRUM_UPDATE_FAIL") {
          setopenSnackErrMsg("Failed to update Spectrum Data");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg("Successfully updated spectrum data");
          setOpenSnack(true);
        }
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const handleClick = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const showAddOptions = () => {
    setIsVisible(!isVisible);
  };

  const changeRatioState = () => {
    if (roles.includes("edit-spectrum")) {
      console.log(lockRatio);
      const updateSpectrumData = {
        channelData: JSON.stringify(spectrum),
        lockRatio: !lockRatio,
        roomId: spectrumNameAct.roomId,
      };

      updateSpectrum(spectrumNameAct.spectrumControlId, updateSpectrumData)
        .then(function (response) {
          console.log("update spectrum" + response.data.code);
          if (response.data.code === "SPECTRUM_UPDATE_FAIL") {
            setopenSnackErrMsg("Failed to update Spectrum Data");
            setOpenSnack(true);
          } else {
            setLockRatio(!lockRatio);
            setopenSnackErrMsg("Successfully updated spectrum data");
            setOpenSnack(true);
          }
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
          }
        });
    }
  };

  const handleActionChange = (event, action) => {
    setRoutineAction(action);
  };

  const handleRoutineNameChange = (event) => {
    setRoutineNameInput(event.target.value);
  };

  const handleSave = () => {
    console.log(routineNameInput);
    console.log(routineTime);
    console.log(routineAction);
    console.log(roomName);
    console.log(spectrumName);

    setOpen(!open);
  };

  const handleSpectrumChange = (event) => {
    setSpectrumNameRot(event.target.value);
  };

  const handleEditClose = () => {
    setOpenEditName(!openEditName);
  };

  const handleEditSave = (event, index) => {
    setSpectrum((prevValues) => {
      const newValues = [...prevValues];
      newValues[lableData.index].channelName = editedChannelName;
      return newValues;
    });

    const updateSpectrumData = {
      channelData: JSON.stringify(spectrum),
      lockRatio: lockRatio,
      roomId: spectrumNameAct.roomId,
    };

    updateSpectrum(spectrumNameAct.spectrumControlId, updateSpectrumData)
      .then(function (response) {
        console.log("update spectrum" + response.data.code);
        if (response.data.code === "SPECTRUM_UPDATE_FAIL") {
          setopenSnackErrMsg("Failed to update Spectrum Data");
          setOpenSnack(true);
        } else {
          setOpenEditName(!openEditName);
          setopenSnackErrMsg("Successfully updated spectrum name");
          setOpenSnack(true);
        }
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const handleLableChange = (event) => {
    setEditedChannelName(event.target.value);
  };

  const handleWeekCheckbox = (event, index) => {
    setWeekValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = 1;
      return newValues;
    });
    console.log(weekValues);
  };

  const handleSaveRoutines = () => {
    var utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    // console.log(dayjs.utc().format());
    console.log("name" + routineNameInput);
    // console.log(dayjs.utc(routineTime).format().split("T")[1].split("Z")[0]);
    // console.log("rot: " + routineTime);
    // console.log(routineAction);
    console.log(roomName);
    console.log(spectrumName);
    // console.log(weekValues);
    if (routineNameInput != "" && roomNameRot && spectrumNameRot) {
      const routine_data = {
        routineName: routineNameInput,
        action: parseInt(routineAction),
        daysInWeek: weekValues.toString(),
        spectrumId: spectrumName.spectrumId,
        spectrumName: spectrumName.spectrumName,
        actionTime: dayjs.utc(routineTime).format().split("T")[1].split("Z")[0],
        roomId: roomName.roomId,
      };
      createRoutine(routine_data)
        .then(function (response) {
          setOpen(!open);
          setopenSnackErrMsg("Successfully Created routine");
          setOpenSnack(true);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid data to continue");
      setOpenSnack(true);
    }
  };

  const handleRoomChangeRoutine = (event) => {
    getAllSpectrumByRoomId(event.target.value.roomId)
      .then(function (response) {
        console.log(response.data.spectrums);
        setRoomNameRot(event.target.value);
        setSpectrumNamesRot(response.data.spectrums);
        setSpectrumNameRot(response.data.spectrums[0]);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          noSpectrumData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoSpectrumData(true);
        }
      });
  };

  const handleNewSpectrum = () => {
    const spectrum_data = {
      roomId: roomDetails.roomId,
      lockRatio: 0,
      spectrumName: spectrumNameText,
      spectrumControl: JSON.stringify(spectrum),
    };

    createSpectrum(undefined, spectrum_data)
      .then(function (response) {
        setOpenSpectrum(false);
        setopenSnackErrMsg("Successfully created Spectrum ");
        setOpenSnack(true);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.message + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleUpdateSpectrum = () => {
    const spectrum_data = {
      roomId: roomDetails.roomId,
      spectrumControlId: spectrumNameAct.spectrumControlId,
    };
    updateSpectrumRoom(roomDetails.roomId, spectrum_data)
      .then(function (response) {
        setOpenSpectrum(false);
        setopenSnackErrMsg("Successfully updated Spectrum ");
        setOpenSnack(true);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleSpectrumNameText = (event) => {
    setSpectrumNameText(event.target.value);
  };

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Typography
        sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
        variant="h5"
        component="div"
      >
        Admin Spectrum
      </Typography>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />

      <Dialog open={openSpectrum} onClose={handleSpectrumClose}>
        <DialogTitle sx={{ ml: 1, mt: 1 }}>Manage Spectrum</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleSpectrumClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {!isVisible && (
            <div>
              <Box sx={{ minWidth: 350, mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Choose Spectrum
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={spectrumNameAct}
                    label="Choose Schedule"
                    onChange={handleChange}
                  >
                    {noData ? (
                      <em>
                        <MenuItem value="NaNa">No Data Found</MenuItem>
                      </em>
                    ) : (
                      spectrumNames.map((spectrumValue, index) =>
                        spectrumValue.status == 1 ? (
                          <MenuItem value={spectrumValue}>
                            {spectrumValue.spectrumName}{" "}
                            <em>&nbsp;(Active) </em>
                          </MenuItem>
                        ) : (
                          <MenuItem value={spectrumValue}>
                            {spectrumValue.spectrumName}
                          </MenuItem>
                        )
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Typography id="input-slider" align="center" sx={{ m: 1 }}>
                Or
              </Typography>
              <Box textAlign="center" mb={3}>
                <Button
                  variant="contained"
                  size="small"
                  position="relative"
                  align="center"
                  justifyContent="center"
                  onClick={showAddOptions}
                >
                  <AddIcon />
                  &nbsp;New Spectrum
                </Button>
              </Box>
            </div>
          )}

          {isVisible && (
            <div>
              <TextField
                autoFocus
                margin="dense"
                size="small"
                id="spectrum_name"
                label="Spectrum Name"
                type="spectrum_name"
                sx={{ width: 350 }}
                variant="outlined"
                value={spectrumNameText}
                onChange={handleSpectrumNameText}
              />
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                mt={0.5}
              >
                <Grid item xs={6}></Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ mb: 1, mr: 1 }}>
          <Button onClick={handleSpectrumClose}>Cancel</Button>
          {isVisible ? (
            <Button onClick={handleNewSpectrum}>Save</Button>
          ) : (
            <Button onClick={handleUpdateSpectrum}>Update</Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openEditName} onClose={handleEditClose}>
        <DialogTitle>Edit Lable</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleEditClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ minWidth: 250 }}>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="name"
              label="Name"
              type="name"
              fullWidth
              value={editedChannelName}
              onChange={handleLableChange}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSave}>Add</Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Paper elevation={2} sx={{ pr: 6, pl: 6, pb: 6, pt: 3 }}>
          <Stack
            direction="row"
            spacing={2}
            mb={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ mb: 2 }} variant="h6">
              {roomName}
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              mb={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={lockRatio} onClick={changeRatioState} />
                  }
                  label="Set Ratio"
                />
              </FormGroup>
              <Button
                variant="contained"
                size="small"
                onClick={handleSpectrumOpen}
                disabled={!roles.includes("add-spectrum")}
              >
                <SaveAsIcon />
                &nbsp;Spectrum
              </Button>
            </Stack>
          </Stack>

          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {spectrum.map((value, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Box sx={{ width: 340 }}>
                  <Paper elevation={2} sx={{ p: 1.5 }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          id="input-slider"
                          color={
                            value.isActive ? "text.primary" : "text.disabled"
                          }
                          disabled={!value.isActive}
                        >
                          {value.channelName}
                        </Typography>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color={value.isActive ? "inherit" : "disabled"}
                          disabled={!value.isActive}
                          onClick={(e) => editLableName(e, index)}
                        >
                          <EditIcon color="action" fontSize="small" />
                        </IconButton>
                      </Stack>

                      <Checkbox
                        checked={value.isActive}
                        onClick={(e) => changeSliderState(e, index)}
                      />
                    </Stack>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Slider
                          value={
                            typeof value.channelValue === "number"
                              ? value.channelValue >= 100
                                ? 100
                                : Math.round(value.channelValue)
                              : 0
                          }
                          onChange={(e) => handleSliderChange(e, index)}
                          onChangeCommitted={handleChangeComplete}
                          valueLabelDisplay="auto"
                          aria-labelledby="input-slider"
                          disabled={!value.isActive}
                          sx={{ mx: 1 }}
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          value={
                            value.channelValue >= 100
                              ? 100
                              : Math.round(value.channelValue)
                          }
                          size="small"
                          onChange={(e) => handleInputChange(e, index)}
                          onBlur={handleBlur}
                          disabled={!value.isActive}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}
