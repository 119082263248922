import { useState, useEffect, Fragment } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/system/Box";
import Divider from "@mui/material/Divider";

import {
  getRooms,
  createOrg,
  getOrgs,
  getSpectrumByRoomId,
  deleteOrg,
} from "../service/service_handler";

export default function Settings() {
  const [roomName, setRoomName] = useState({});
  const [roomNames, setRoomNames] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [noData, setNoData] = useState(false);
  const [openSnackErrMsg, setopenSnackErrMsg] = useState("");
  const [spectrums, setspectrums] = useState([]);
  const [openOrgAdd, setOpenOrgAdd] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgCode, setOrgCode] = useState("");
  const [orgDatas, setOrgDatas] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    getOrgs()
      .then(function (response) {
        setOrgDatas(response.data.organizations);
        console.log("Org data: " + JSON.stringify(response.data.organizations));
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  }, []);

  const handleDropdownChange = (event) => {
    // setRoomName(event.target.value);
    getSpectrumByRoomId(event.target.value.roomId)
      .then(function (response) {
        setRoomName(event.target.value);
        setspectrums(response.data.spectrums);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleOrgClose = () => {
    setOrgName("");
    setOrgCode("");
    setOpenOrgAdd(!openOrgAdd);
  };

  const handleOpenOrgAdd = () => {
    const { nanoid } = require("nanoid");
    setOrgName("");
    setOrgCode(nanoid(10).toUpperCase());
    setOpenOrgAdd(true);
  };

  const onOrgNameChange = (event) => {
    setOrgName(event.target.value);
  };

  const handleOrgAdd = () => {
    const org_data = {
      orgName: orgName,
      orgCode: orgCode,
      // deviceSerialNo: deviceIdText,
    };
    if (orgName != "" && orgCode != "") {
      createOrg(org_data)
        .then(function (response) {
          updateOrgAdd();
          // window.location.reload(false);
          setOpenOrgAdd(!openOrgAdd);
          setopenSnackErrMsg("Successfully Created Organization ");
          setOpenSnack(true);
        })
        .catch((error) => {
          console.log("service-error", error);
          if (!error.response) {
            setopenSnackErrMsg("Network error please try again");
            setOpenSnack(true);
            setNoData(true);
          } else {
            setopenSnackErrMsg(
              error.response.data.code + " - Please try again later"
            );
            setOpenSnack(true);
            setNoData(true);
          }
        });
    } else {
      setopenSnackErrMsg("Please enter valid Data!");
      setOpenSnack(true);
    }
  };

  const handleConfirmedDelete = () => {
    deleteOrg(deleteId)
      .then(function (response) {
        setOpenDelete(false);
        setopenSnackErrMsg("Successfully deleted Org");
        setOpenSnack(true);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
        }
      });
  };

  const handleClickOpenDelete = (row) => {
    if (row.org_code != "SUPER_ADMIN") {
      setOpenDelete(true);
      setDeleteId(row.organization_id);
    } else {
      setopenSnackErrMsg("Cannot delete Admin user");
      setOpenSnack(true);
    }
  };

  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
    setDeleteId("");
  };

  const updateOrgAdd = () => {
    getOrgs()
      .then(function (response) {
        setOrgDatas(response.data.organizations);
        // console.log("Org data: " + JSON.stringify(response.data.organizations));
      })
      .catch((error) => {
        console.log("service-error", error);
        if (!error.response) {
          setopenSnackErrMsg("Network error please try again");
          setOpenSnack(true);
          setNoData(true);
        } else {
          setopenSnackErrMsg(
            error.response.data.code + " - Please try again later"
          );
          setOpenSnack(true);
          setNoData(true);
        }
      });
  };

  const snackAction = (
    <Fragment>
      {/* <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Typography
        sx={{ mb: 1.5, fontWeight: "bold", color: "text.primary" }}
        variant="h5"
        component="div"
      >
        Admin Home
      </Typography>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        message={openSnackErrMsg}
        action={snackAction}
      />
      <Stack
        direction="row"
        spacing={2}
        mb={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Button variant="contained" onClick={handleOpenOrgAdd}>
          <AddIcon />
          NEW ORGANIZATION
        </Button>
      </Stack>

      <Dialog open={openOrgAdd} onClose={handleOrgClose}>
        <DialogTitle>New Organization</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOrgClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="org_name"
              label="Organization Name"
              type="organization_name"
              fullWidth
              value={orgName}
              onChange={onOrgNameChange}
              variant="outlined"
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="organization_code"
              label="Organization Code"
              type="organization_code"
              fullWidth
              value={orgCode}
              // onChange={onDeviceIdChange}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOrgClose}>Cancel</Button>
          <Button onClick={handleOrgAdd}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please confirm to Delete ?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeletePopup}>No</Button>
          <Button onClick={handleConfirmedDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Box>
        {/* <Paper elevation={2} sx={{ pr: 6, pl: 6, pb: 6, pt: 3, mr: 45 }}>
          {orgDatas.map((row) => (
            <div>
              <Box>
                <Paper elevation={2} sx={{ pr: 6, pl: 6, pb: 2, pt: 2, mb: 2 }}>
                  <Stack
                    direction="row"
                    spacing={4}
                    divider={<Divider orientation="vertical" flexItem />}
                    justifyContent="space-evenly"
                    alignItems="center"
                    // sx={{ ml: 0 }}
                    // justifyContent="center"
                    // alignItems="center"

                    // width={600}
                  >
                    <Typography
                      minWidth={100}
                      maxWidth={120}
                      variant="body1"
                      gutterBottom
                    >
                      {row.org_name}
                    </Typography>
                    <Typography
                      minWidth={100}
                      maxWidth={120}
                      variant="body1"
                      gutterBottom
                    >
                      {row.org_code}
                    </Typography>
                    <Typography
                      minWidth={100}
                      maxWidth={120}
                      variant="body1"
                      gutterBottom
                    >
                      {row.users_count == null ? 0 : row.users_count} Users
                    </Typography>
                    <Typography
                      minWidth={100}
                      maxWidth={120}
                      variant="body1"
                      gutterBottom
                    >
                      {row.device_count == null ? 0 : row.device_count} Devices
                    </Typography>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() => handleClickOpenDelete(row)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </Paper>
              </Box>
            </div>
          ))}
        </Paper> */}
      </Box>

      <TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Organization Name</TableCell>

              <TableCell align="right">Organization Code</TableCell>
              <TableCell align="right">Total Users</TableCell>
              <TableCell align="right">Total Devices</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orgDatas.map((row) => (
              <TableRow
                key={row.org_name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.org_name}
                </TableCell>

                <TableCell align="right">{row.org_code}</TableCell>
                <TableCell align="right">
                  {row.users_count == null ? 0 : row.users_count} Users
                </TableCell>
                <TableCell align="right">
                  {row.device_count == null ? 0 : row.device_count} Devices
                </TableCell>

                <TableCell align="right">
                  {
                    <Stack
                      direction="row"
                      spacing={1.5}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        size="small"
                        aria-label="close"
                        sx={{ ml: 1 }}
                        onClick={() => handleClickOpenDelete(row)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Spectrum Name</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Ratio Locked</TableCell>
              <TableCell align="right">Created On</TableCell>
              <TableCell align="right">Updated On</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spectrums.map((row) => (
              <TableRow
                key={row.spectrumName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.spectrumName}
                </TableCell>
                <TableCell align="right">
                  {row.status == 1 ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "success.light",
                        "&:hover": {
                          bgcolor: "success.light",
                        },
                      }}
                    >
                      ACTIVE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        bgcolor: "grey",
                        "&:hover": {
                          bgcolor: "grey",
                        },
                      }}
                    >
                      INACTIVE
                    </Button>
                  )}
                </TableCell>
                <TableCell align="right">
                  {row.lockRatio == 1 ? "True" : "False"}
                </TableCell>
                <TableCell align="right">{row.createdAt}</TableCell>
                <TableCell align="right">{row.updatedAt}</TableCell>
                <TableCell align="right">
                  {
                    <Stack
                      direction="row"
                      spacing={1.5}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton aria-label="view">
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </div>
  );
}
